/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../calls/Loader';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, eachWeekOfInterval, eachMonthOfInterval, endOfWeek, endOfMonth, subDays } from 'date-fns';
import { getDashboardStats, getProducts, getSalesSummary, getSalesCount } from '../../calls/auths';

const AdminDashboard = ({ token }) => {
  const [dateRange, setDateRange] = useState(() => {
    const end = new Date();
    const start = subDays(end, 29); // 30 days including today
    return [start, end];
  });

  
  const [startDate, endDate] = dateRange;
  const [salesData, setSalesData] = useState([]);
  const [stats, setStats] = useState({ daily: 0, weekly: 0, monthly: 0 });
  const [responseData, setResponseData] = useState(null);
  const [products, setProducts] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [salesSummary, setSalesSummary] = useState(null);
  const [salesCount, setSalesCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateTotalQuantity = (product) => {
    return product.measurements.reduce((total, measurement) => {
      return total + measurement.colors.reduce((sum, color) => sum + color.amount, 0);
    }, 0);
  };

  const calculateTotalPrice = (product) => {
    // Sum up the prices from the 'measurements' array
    return product.measurements.reduce((total, measurement) => {
      return total + measurement.price;
    }, 0);
  };

  const calculateTotalCostPrice = (product) => {
    // If no measurements exist, return 0
    if (!product.measurements || !Array.isArray(product.measurements)) {
      return 0;
    }
  
    // Sum up valid costPrice values in measurements
    return product.measurements.reduce((sum, measurement) => {
      const costPrice = measurement.costPrice;
      // Check if costPrice is a valid number
      return sum + (typeof costPrice === "number" ? costPrice : 0);
    }, 0);
  };
  
  // const calculateTotalCostPrice = (product) => {
  //   // Sum up the prices from the 'measurements' array
  //   return product.measurements.reduce((total, measurement) => {
  //     return total + measurement.costPrice;
  //   }, 0);
  // };

  // Fetch products and calculate quantity and price
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        setProducts(response.data);
        const totalQuantity = response.data.reduce((sum, product) => sum + calculateTotalQuantity(product), 0);
        const totalPrice = response.data.reduce((sum, product) => sum + calculateTotalPrice(product), 0);
        // const totalCostPrice = response.data.reduce((sum, product) => sum + calculateTotalCostPrice(product), 0);
        const totalCostPrice = response.data.reduce((sum, product) => {
          // Add total cost price of each product to the sum
          return sum + calculateTotalCostPrice(product);
        }, 0);
        response.data.forEach((product) => {
          console.log("Product ID:", product._id);
          console.log("Total Cost for Product:", calculateTotalCostPrice(product));
        });
        
        console.log("Final Total Cost Price:", totalCostPrice);
        
        setTotalQuantity(totalQuantity);
        setTotalPrice(totalPrice);
        setTotalCostPrice(totalCostPrice);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Fetch sales data and dashboard stats
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardStats(startDate, endDate); // Adjust the API call parameters as needed
        console.log('Dashboard Stats:', response.data);

        const data = response.data.dailyAverage.map(item => ({
          date: format(new Date(item.orderDate), 'yyyy-MM-dd'),
          sales: item.totalAmount,
        }));

        setSalesData(data);
        // calculateStats(data);
        setResponseData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  // Calculate daily, weekly, and monthly averages
  useEffect(() => {
    const calculateStats = (data) => {
      if (!data.length) return { daily: 0, weekly: 0, monthly: 0 };

      const totalSales = data.reduce((sum, day) => sum + day.sales, 0);
      const days = data.length;
      const weeks = Math.ceil(days / 7);
      const months = eachMonthOfInterval({ start: startDate, end: endDate }).length;

      setStats({
        daily: Math.round(totalSales / days),
        weekly: Math.round(totalSales / weeks),
        monthly: Math.round(totalSales / months),
      });
    };

    if (salesData.length) {
      calculateStats(salesData);
    }
  }, [salesData, startDate, endDate]);

  const aggregateSalesByPeriod = (period) => {
    if (!startDate || !endDate) return [];

    let intervals;
    let formatString;

    switch (period) {
      case 'week':
        intervals = eachWeekOfInterval({ start: startDate, end: endDate });
        formatString = 'MMM d';
        break;
      case 'month':
        intervals = eachMonthOfInterval({ start: startDate, end: endDate });
        formatString = 'MMM yyyy';
        break;
      default:
        return salesData;
    }

    return intervals.map(intervalStart => {
      const intervalEnd = period === 'week' ? endOfWeek(intervalStart) : endOfMonth(intervalStart);
      const salesInInterval = salesData.filter(sale => 
        new Date(sale.date) >= intervalStart && new Date(sale.date) <= intervalEnd
      );
      const totalSales = salesInInterval.reduce((sum, sale) => sum + sale.sales, 0);
      return {
        date: format(intervalStart, formatString),
        sales: totalSales,
      };
    });
  };

  const calculateSalesRepSales = (data) => {
    const salesRepSales = {};
    const salesRepNames = {};

    data?.forEach(order => {
      const repId = order.salesRep.id;
      const repName = order.salesRep.name;

      if (salesRepSales[repId]) {
        salesRepSales[repId] += order.total;
      } else {
        salesRepSales[repId] = order.total;
        salesRepNames[repId] = repName;
      }
    });

    return Object.entries(salesRepSales).map(([id, totalSales]) => ({
      name: salesRepNames[id],
      totalSales
    }));
  };

  const salesRepData = calculateSalesRepSales(responseData?.posSales);

  useEffect(() => {
    const fetchSalesSummary = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('tripstore_login'); // Make sure you're storing the token like this after login
        if (!token) {
          throw new Error('No token found. Please log in.');
        }
        const response = await getSalesSummary(token);
        if (response.status === 200) {
          setSalesSummary(response.data);
        } else if (response.status === 401) {
          setError('Unauthorized. Please log in again.');
        } else {
          setError('Failed to fetch sales summary');
        }
      } catch (err) {
        setError('An error occurred while fetching sales summary');
      } finally {
        setLoading(false);
      }
    };

    fetchSalesSummary();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('tripstore_login');
        if (!token) {
          throw new Error('No token found. Please log in.');
        }
        const [summaryResponse, countResponse] = await Promise.all([
          getSalesSummary(token),
          getSalesCount(token)
        ]);
        setSalesSummary(summaryResponse.data);
        setSalesCount(countResponse.data);
      } catch (err) {
        setError(err.message);
        if (err.message.includes('Unauthorized')) {
          // Redirect to login page or show login modal
          // window.location.href = '/login';
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to generate a random color
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random()   
 * 16)];
  }
  return color;
}

const dashboardItems = document.querySelectorAll('.dashboard-item');
dashboardItems.forEach(item => {
  item.style.backgroundColor = getRandomColor();
});

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  const totalSalesC = (salesCount?.totalSales || 0) + (responseData?.totalProductsSold || 0);
  const totalStock = totalSalesC + (totalQuantity || 0);
  console.log(totalCostPrice);

  return (
    <div className="bg-white min-h-screen p-6">
      <h1 className='text-2xl text-center font-semibold mb-3 uppercase'>Stock</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8 items-center">
      <div className="bg-green-700 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
            <h2 className="text-white font-bold">Available Stock</h2>
            <p className="text-4xl font-bold text-white py-2">
                {totalQuantity.toLocaleString()}
            </p>
        </div>
        <div className="bg-yellow-500 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
            <h2 className="text-gray-800 font-bold">Total No of Sold Stock</h2>
            <p className="text-4xl font-bold text-white py-2">
            {totalSalesC.toLocaleString()}
            </p>
        </div>
        <div className="bg-blue-800 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
            <h2 className="text-white font-bold">Total No of Stock</h2>
            <p className="text-4xl font-bold text-white py-2">
                {totalStock.toLocaleString()}
            </p>
        </div>
        <div className="bg-gray-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
            <h2 className="text-gray-800 font-bold">Total Cost Price</h2>
            <p className="text-2xl font-bold text-white py-2">
                #{totalCostPrice.toLocaleString()}
            </p>
        </div>
        <div className="bg-orange-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
            <h2 className="text-gray-800 font-bold">Total Value of Stock</h2>
            <p className="text-2xl font-bold text-white py-2">
                #{totalPrice.toLocaleString()}
            </p>
        </div>
        </div>
        <h1 className='text-2xl text-center font-semibold mb-3 uppercase'>Store Sales</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8">
      <div className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
        <h2 className="text-gray-800 font-bold">Total Sales</h2>
        <p className="text-4xl font-semibold text-white py-2">
          {salesCount ? salesCount.totalSales.toLocaleString() : 'N/A'}
        </p>
      </div>
      <div className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
        <h2 className="text-gray-800 font-bold">Sales Last 30 Days</h2>
        <p className="text-4xl font-semibold text-white py-2">
          +{salesCount ? salesCount.salesLast30Days.toLocaleString() : 'N/A'}
        </p>
        <p className={`${parseFloat(salesCount?.percentageChange) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
          {salesCount ? `${salesCount.percentageChange}% from previous 30 days` : 'N/A'}
        </p>
      </div>
      {['daily', 'weekly', 'monthly', 'quarterly', 'halfYearly', 'yearly', 'totalAllTime'].map((period) => (
        <div key={period} className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
          <h2 className="text-gray-800 font-bold capitalize">{period.replace('Y', ' Y')} Revenue</h2>
          <p className="text-2xl font-semibold text-white py-2">
            ₦ {salesSummary && salesSummary[period] ? salesSummary[period].toLocaleString() : '0'}
          </p>
        </div>
      ))}
    </div>
        <h1 className='text-2xl text-center font-semibold mb-3 uppercase'>Online Sales</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div class="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
    <h2 class="text-gray-800 font-bold">Online Revenue</h2>
    <p class="text-2xl font-bold text-gray-800 py-2">₦ {responseData ? responseData.totalRevenue.toLocaleString() : 'Loading...'}</p>
    <p class="text-white">+{responseData ? responseData.revenueChange + '% from last 30 days' : 'Loading...'}</p>
  </div>
  <div class="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
    <h2 class="text-gray-800 font-bold">Total Clients</h2>
    <p class="text-2xl font-bold text-gray-800 py-2">+{responseData ? responseData.totalUsers.toLocaleString() : 'Loading...'}</p>
    <p class="text-white">+{responseData ? responseData.usersChange + '% from last 30 days' : 'Loading...'}</p>
  </div>
  <div class="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
    <h2 class="text-gray-800 font-bold">Sales</h2>
    <p class="text-2xl font-bold text-gray-800 py-2">+{responseData ? responseData.totalProductsSold.toLocaleString() : 'Loading...'}</p>
    <p class="text-white">+{responseData ? responseData.productsSoldChange + '% from last 30 days' : 'Loading...'}</p>
  </div>
  <div class="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
    <h2 class="text-gray-800 font-bold">Total Shipped</h2>
    <p class="text-2xl font-bold text-gray-800 py-2">+{responseData ? responseData.totalProductsShipped.toLocaleString() : 'Loading...'}</p>
    <p class="text-white">+{responseData ? responseData.totalProductsShipped + '% from last 30 days' : 'Loading...'}</p>
  </div>
      </div>

      <div className="w-full flex flex-col md:flex-row">
      <div className="w-full md:w-3/5 mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Overview</h2>
        <div className="flex items-center gap-4">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            className="border rounded px-4 py-2"
            placeholderText="Select date range"
            dateFormat="MMM d, yyyy"
            maxDate={new Date()} // Prevent selecting future dates
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="bg-blue-100 p-4 rounded">
          <h3 className="text-xl font-semibold mb-2">Daily Average</h3>
          <p className="text-3xl font-bold">₦ {stats.daily.toLocaleString()}</p>
        </div>
        <div className="bg-green-100 p-4 rounded">
          <h3 className="text-xl font-semibold mb-2">Weekly Average</h3>
          <p className="text-3xl font-bold">₦ {stats.weekly.toLocaleString()}</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded">
          <h3 className="text-xl font-semibold mb-2">Monthly Average</h3>
          <p className="text-3xl font-bold">₦ {stats.monthly.toLocaleString()}</p>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Daily Sales</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={salesData}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sales" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Weekly Sales</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={aggregateSalesByPeriod('week')}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sales" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Monthly Sales</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={aggregateSalesByPeriod('month')}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sales" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
    <div className='flex flex-col w-full md:w-2/5'>
        <div className="bg-gray-100 p-4 rounded">
          <h3 className="text-gray-800 font-bold text-xl">Recent Sales</h3>
          {responseData?.recentSalesWithName.length === 0 ? (
            <p className="text-gray-600">No recent sales.</p>
          ) : (
            <ul>
              {responseData?.recentSalesWithName.slice(0, 5).map((item, index) => (
                <li key={index} className="flex justify-between items-center py-2">
                  <span>{item.username}</span>
                  <span className="font-bold text-green-500">+₦{item.totalAmount.toLocaleString()}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="bg-gray-100 p-4 rounded mt-5">
          <h3 className="text-gray-800 font-bold text-xl">Pending Shippings</h3>
          {responseData?.recentSalesWithName.filter(item => item.status === "ongoing").length === 0 ? (
            <p className="text-gray-600">No pending shippings.</p>
          ) : (
            <ul>
              {responseData?.recentSalesWithName
                .filter(item => item.status === "ongoing")
                .slice(0, 5)
                .map((item, index) => (
                  <li key={index} className="flex justify-between items-center py-2">
                    <span>{item.username}</span>
                    <span className="font-bold text-green-500">Ongoing</span>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div className="bg-gray-100 p-4 rounded mt-5">
          <h3 className="text-gray-800 font-bold text-xl">Sales Rep Sales</h3>
          <ul>
            {salesRepData.length ? (
              salesRepData.map(rep => (
                <li key={rep.name} className="flex justify-between items-center py-2">
                  <span>{rep.name}</span>
                  <span className="font-bold text-green-500">₦ {rep.totalSales.toLocaleString()}</span>
                </li>
              ))
            ) : (
              <li>No sales data available</li>
            )}
          </ul>
        </div>
    </div>
      </div>
    </div>
    )
}

export default AdminDashboard

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { NavLink, useNavigate } from "react-router-dom";
import Bg from "./assets/bg.avif";
import Logo from "./assets/ic.png";
import Shopping from "./assets/vendor.jpg";
import { FaAnglesRight } from "react-icons/fa6";
import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "./context/AppContext";
import {
	googleAuth,
	registerVendor,
	getVendorInfo,
	vendorLogin,
} from "./calls/auths";
import MessageResponse from "./calls/MessageResponse";
import Loader from "./calls/Loader";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

const VendorLogin = () => {
	const { user, setLoggedIn, setUser } = useContext(AppContext);

	const [currentStep, setCurrentStep] = useState(1);
	//   const [regInputs, setRegInputs] = useState<RegInputs>({});
	//   const regFormRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		let timer;

		const resetTimer = () => {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				handleLogout();
			}, 1800000);
		};

		const events = ["click", "touchstart", "scroll", "mousemove", "keypress"];
		events.forEach((event) => window.addEventListener(event, resetTimer));

		resetTimer();

		return () => {
			clearTimeout(timer);
			events.forEach((event) => window.removeEventListener(event, resetTimer));
		};
	}, []);
	const Navigate = useNavigate();

	const [showSignUp, setShowSignUp] = useState(false);
	const [loginInputs, setLoginInputs] = useState({});
	const [regInputs, setRegInputs] = useState({
		social_auth: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [apiRes, setApiRes] = useState();
	const [apiMsg, setApiMsg] = useState();

	const regFormRef = useRef(null); // Create a reference to the registration form
	const loginFormRef = useRef(null); // Create a reference to the login form

	const toggleSignUp = () => {
		setShowSignUp(!showSignUp);
	};

	const handleLoginInputsChange = (e) => {
		const { name, value } = e.target;
		setLoginInputs({ ...loginInputs, [name]: value });
	};

	const handleNext = () => {
		if (currentStep < 3) setCurrentStep(currentStep + 1);
	};

	const handlePrev = () => {
		if (currentStep > 1) setCurrentStep(currentStep - 1);
	};

	const onRegSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const response = await registerVendor(regInputs);
		setApiRes(response);
		setApiMsg(response.data.message || "Vendor Registered Successfully");
		setIsLoading(false);

		if (response.data.token) {
			window.localStorage.setItem("tripstore_login", response.data.token);
            const loginSession = window.localStorage.getItem("tripstore_login");
			const user = await getVendorInfo(loginSession);
			setUser(user.data);
			setLoggedIn(true);
			const userType = "vendor";
			window.localStorage.setItem("vendor", userType);

		}
		Navigate("/vendor/dashboard")

		setTimeout(() => {
			setApiRes();
		}, 5000);
	};


	const handleLogout = () => {
		window.localStorage.removeItem("tripstore_login");
        window.localStorage.removeItem("vendor");
		setUser(null);
		setLoggedIn(false);
		Navigate("/vendors");
	};

	const handleRegInputsChange = (e) => {
		const { name, value } = e.target;
		setRegInputs({ ...regInputs, [name]: value });
	};

	const generatePassword = () => {
		return Math.random().toString(36).slice(-8);
	};

	const onLoginSubmit = async (e) => {
		const hasPreviousPage = window.history.length > 1;
		e.preventDefault();
		setIsLoading(true);
		const response = await vendorLogin(loginInputs);
		setApiRes(response);
		setApiMsg(response.data.message || "Vendor Logged In Successfully");
		setIsLoading(false);

		if (response.data.token) {
			window.localStorage.setItem("tripstore_login", response.data.token);
			const loginSession = window.localStorage.getItem("tripstore_login");
			const user = await getVendorInfo(loginSession);
			setUser(user.data);
			setLoggedIn(true);
			const userType = "vendor";	
			window.localStorage.setItem("vendor", userType);		
		}

		Navigate("/vendor/dashboard")

		setTimeout(() => {
			setApiRes();
		}, 5000);
	};

	const googleForm = async (inputs) => {
		setIsLoading(true);
		const response = await googleAuth(inputs);
		setApiRes(response);
		setApiMsg(response.data.message || "User Logged In");
		setIsLoading(false);

		if (response.data.token) {
			window.localStorage.setItem("tripstore_login", response.data.token);
			const loginSession = window.localStorage.getItem("tripstore_login");
			const user = await getVendorInfo(loginSession);
			setUser(user.data);
			setLoggedIn(true);
			const userType = jwtDecode(response.data.token);
			const isUser = userType.user_type === "user";
			const isAdmin = userType.user_type === "admin";
			const isSupervisor = userType.user_type === "supervisor";
			const isSalesRep = userType.user_type === "sales_rep";

			if (isUser) {
				Navigate("/");
			} else if (isAdmin) {
				Navigate("/admin/dashboard");
			} else if (isSalesRep) {
				Navigate("/sales_rep/dashboard");
			} else if (isSupervisor) {
				Navigate("/supervisor/inventory");
			}
		}

		setTimeout(() => {
			setApiRes();
		}, 5000);
	};

	// const onRegSubmit = async (e) => {
	//     e.preventDefault();
	//     setIsLoading(true);
	//     const response = await userRegister(regInputs);
	//     setApiRes(response);
	//     setApiMsg(response.data.message || "User Logged In");
	//     setIsLoading(false);

	//     if (response.data.token) {
	//         window.localStorage.setItem("tripstore_login", response.data.token);
	//         const loginSession = window.localStorage.getItem("tripstore_login");
	//         const user = await getUserInfo(loginSession);
	//         setUser(user.data);
	//         setLoggedIn(true);
	//         const userType = jwtDecode(response.data.token);
	//         const isUser = userType.user_type === "user";
	//         const isAdmin = userType.user_type === "admin";
	//         const isSalesRep = userType.user_type === "sales_rep";
	//         const isSupervisor = userType.user_type === "supervisor";

	//         if (isUser) {
	//             Navigate('/');
	//         } else if (isAdmin) {
	//             Navigate('/admin/dashboard');
	//         } else if (isSalesRep) {
	//             Navigate('/sales_rep/dashboard');
	//         }else if (isSupervisor) {
	//             Navigate('/supervisor/inventory');
	//         }
	//     }

	//     setTimeout(() => {
	//         setApiRes();
	//     }, 5000);
	// };

	const signUp = useGoogleLogin({
		onSuccess: async (response) => {
			try {
				const res = await axios.get(
					"https://www.googleapis.com/oauth2/v3/userinfo",
					{
						headers: {
							Authorization: `Bearer ${response.access_token}`,
						},
					}
				);
				const { given_name, family_name, email } = res.data;
				const password = generatePassword();

				const newRegInputs = {
					firstname: given_name,
					lastname: family_name,
					email,
					pwd: password,
					c_pwd: password,
					user_type: "user",
					social_auth: true,
				};
				setRegInputs(newRegInputs);
				setTimeout(() => {
					googleForm(newRegInputs);
				}, 0);
			} catch (err) {
				console.log("err");
			}
		},
	});

	return (
		<div
			className="relative w-full h-screen bg-cover bg-no-repeat bg-center"
			style={{ backgroundImage: `url(${Bg})` }}
		>
			<div className="absolute top-0 left-0 w-full h-full bg-secondary/10 flex justify-center items-center">
				<div className="relative rounded-sm overflow-hidden shadow-lg bg-white/90 backdrop-blur-sm w-[95%] max-w-[1000px] flex items-center">
					<div
						className={`${showSignUp ? "hidden sm:block" : "block"} w-full p-8`}
					>
						{isLoading && <Loader className="w-full md:w-1/2" />}
						<NavLink to={"/"}>
							<img src={Logo} className="h-[80px] m-auto" alt="" />
						</NavLink>
						<div className="text-gray-500 font-semibold mt-2 text-center">
							Welcome Back, Please login to your account
						</div>
						{apiRes && <MessageResponse response={apiRes} msg={apiMsg} />}
						<form
							ref={loginFormRef}
							onSubmit={onLoginSubmit}
							action=""
							className="w-full mt-2"
						>
							<div className="flex flex-col my-2">
								<label htmlFor="log-email" className="text-sm font-semibold">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="log-email"
									onChange={handleLoginInputsChange}
									required
								/>
							</div>
							<div className="flex flex-col my-2">
								<label htmlFor="log-pwd" className="text-sm font-semibold">
									Password
								</label>
								<input
									type="password"
									name="pwd"
									id="log-pwd"
									onChange={handleLoginInputsChange}
									required
								/>
							</div>
							<a
								href="/forgot-password"
								className="text-sm font-semibold hover:underline mt-2"
							>
								Forgot password
							</a>
							<button
								className="w-full bg-tertiary text-white mt-4"
								type="submit"
							>
								Login
							</button>
						</form>
						<button
							className="w-full bg-tertiary text-white mt-4 flex items-center justify-center"
							onClick={() => signUp()}
						>
							Continue with Google <FcGoogle className="ml-2" />
						</button>
						<div className="mt-4 flex items-center gap-2 text-sm font-semibold">
							<div className="flex-1 text-gray-500">
								Don't have an account yet?
							</div>
							<div
								onClick={toggleSignUp}
								className="flex flex-nowrap items-center gap-2 hover:text-primary cursor-pointer"
							>
								<div className="text-nowrap">Sign Up</div>
								<FaAnglesRight />
							</div>
						</div>
					</div>
					<div
						className={`${showSignUp ? "block" : "hidden sm:block"} w-full p-8`}
					>
						{isLoading && <Loader className="w-full md:w-1/2" />}
						<NavLink to={"/"}>
							<img src={Logo} className="h-[80px] m-auto" alt="" />
						</NavLink>
						<div className="text-gray-500 font-semibold mt-2 text-center">
							Great to have you, Please register a new account
						</div>
						{apiRes && <MessageResponse response={apiRes} msg={apiMsg} />}
						<form
							ref={regFormRef}
							onSubmit={onRegSubmit}
							action=""
							className="w-full mt-2"
						>
							{currentStep === 1 && (
								<>
									<div className="flex flex-col my-2">
										<label htmlFor="f-name" className="text-sm font-semibold">
											First Name
										</label>
										<input
											type="text"
											name="firstname"
											id="f-name"
											value={regInputs.firstname || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label htmlFor="l-name" className="text-sm font-semibold">
											Last Name
										</label>
										<input
											type="text"
											name="lastname"
											id="l-name"
											value={regInputs.lastname || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label htmlFor="email" className="text-sm font-semibold">
											Email
										</label>
										<input
											type="email"
											name="email"
											id="email"
											value={regInputs.email || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label htmlFor="pwd" className="text-sm font-semibold">
											Password
										</label>
										<input
											type="password"
											name="password"
											id="pwd"
											value={regInputs.password || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
								</>
							)}

							{currentStep === 2 && (
								<>
									<div className="flex flex-col my-2">
										<label
											htmlFor="businessName"
											className="text-sm font-semibold"
										>
											Business Name
										</label>
										<input
											type="text"
											name="businessName"
											id="businessName"
											value={regInputs.businessName || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="businessAddress"
											className="text-sm font-semibold"
										>
											Business Address
										</label>
										<input
											type="text"
											name="businessAddress"
											id="businessAddress"
											value={regInputs.businessAddress || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="businessPhone"
											className="text-sm font-semibold"
										>
											Business Phone
										</label>
										<input
											type="text"
											name="businessPhone"
											id="businessPhone"
											value={regInputs.businessPhone || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="businessIGpage"
											className="text-sm font-semibold"
										>
											Business Instagram Page
										</label>
										<input
											type="text"
											name="businessIGpage"
											id="businessIGpage"
											value={regInputs.businessIGpage || ""}
											onChange={handleRegInputsChange}
										/>
									</div>
								</>
							)}

							{currentStep === 3 && (
								<>
									<div className="flex flex-col my-2">
										<label htmlFor="bankName" className="text-sm font-semibold">
											Bank Name
										</label>
										<input
											type="text"
											name="bankName"
											id="bankName"
											value={regInputs.bankName || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="accountNumber"
											className="text-sm font-semibold"
										>
											Account Number
										</label>
										<input
											type="text"
											name="accountNumber"
											id="accountNumber"
											value={regInputs.accountNumber || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="accountName"
											className="text-sm font-semibold"
										>
											Account Name
										</label>
										<input
											type="text"
											name="accountName"
											id="accountName"
											value={regInputs.accountName || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
									<div className="flex flex-col my-2">
										<label
											htmlFor="whatsappNumber"
											className="text-sm font-semibold"
										>
											WhatsApp Number
										</label>
										<input
											type="text"
											name="whatsappNumber"
											id="whatsappNumber"
											value={regInputs.whatsappNumber || ""}
											onChange={handleRegInputsChange}
											required
										/>
									</div>
								</>
							)}

							<div className="flex justify-between mt-4">
								{currentStep > 1 && (
									<button
										type="button"
										className="bg-tertiary text-white px-4 py-2 rounded"
										onClick={handlePrev}
									>
										Prev
									</button>
								)}
								{currentStep < 3 ? (
									<button
										type="button"
										className="bg-black text-white px-4 py-2 rounded"
										onClick={handleNext}
									>
										Next
									</button>
								) : (
									<button
										type="submit"
										className="bg-green-500 text-white px-4 py-2 rounded"
									>
										Submit
									</button>
								)}
							</div>

							{/* <button
								className="w-full bg-tertiary text-white mt-4"
								type="submit"
							>
								Register
							</button> */}
						</form>
						<div className="mt-4 flex items-center gap-2 text-sm font-semibold">
							<div className="flex-1 text-gray-500">
								Already have an account?
							</div>
							<div
								onClick={toggleSignUp}
								className="flex flex-nowrap items-center gap-2 hover:text-primary cursor-pointer"
							>
								<div className="text-nowrap">Login</div>
								<FaAnglesRight />
							</div>
						</div>
					</div>
					<div
						className={`absolute top-0 ${
							showSignUp ? "right-[50%]" : "right-[0%]"
						} transition-all duration-500 w-[0%] sm:w-[50%] h-full z-10 bg-secondary`}
					>
						<img src={Shopping} className="w-full h-full object-cover" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default VendorLogin;

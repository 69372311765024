import React, { useState, useRef, useEffect, useContext } from 'react';
import fetchProductInfo from './fetchProductInfo';
import PrintableReceipt from './PrintableReceipt';
import PrintableInvoice from './PrintableInvoice';
import Modal from './Modal';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { activateCoupon, API_ENDPOINT, createSale, checkPointDet, stockUpdate } from '../../calls/auths';
import { AppContext } from "../../context/AppContext";

const Sales = ({ pausedSale }) => {
  const { user } = useContext(AppContext);
  const [products, setProducts] = useState(pausedSale ? pausedSale.products : []);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const [order, setOrder] = useState(null);
  const [salesRep, setSalesRep] = useState(pausedSale ? pausedSale.salesRep : null);
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [coupon, setCoupon] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [pointsDet, setPointsDet] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isCheckoutSuccessful, setIsCheckoutSuccessful] = useState(false);
  const inputRef = useRef();
  const invoiceRef = useRef();
  const receiptRef = useRef();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // To manage input disabling

  useEffect(() => {
    if (inputRef.current && !isFetching) {
      inputRef.current.focus();
    }
    if (!salesRep && user) {
      setSalesRep({
        id: user._id,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email
      });
    }
  }, [salesRep, user, isFetching]);

   // Calculate total, VAT, and discounted total
   const calculateTotal = () => {
    return products.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  const calculateVAT = (total) => {
    return total * 0.075;
  };

  const calculateDiscountedTotal = () => {
    const total = calculateTotal();
    const vat = calculateVAT(total);
    const grandTotal = total + vat;
    if (appliedCoupon) {
      const discountAmount = grandTotal * (appliedCoupon.discount / 100);
      return grandTotal - discountAmount;
    }

    if (pointsDet) {
      return pointsDet.discountedAmount;
    }
    return grandTotal;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setScannedBarcode(e.target.value);
  };

  // Handle quantity change in the table
  const handleQuantityChange = (e, index) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity < 1) return;
    const product = products[index];
    const availableStock = product.selectedColor.amount;

    if (newQuantity > availableStock) {
      alert(`Cannot increase the Product quantity more than (${availableStock}) available in stock  for this color.`);
      return;
    }

    setProducts(products.map((p, i) =>
      i === index ? { ...p, quantity: newQuantity } : p
    ));
  };

  // Handle reducing quantity by 1
  const handleReduceQuantity = (index) => {
    setProducts(products.map((p, i) =>
      i === index ? { ...p, quantity: p.quantity - 1 } : p
    ).filter((p) => p.quantity > 0));
  };

  // Handle removing a product from the table
  const handleRemoveProduct = (targetIndex) => {
    const newArray = products.filter((item, index) => index !== targetIndex);
    setProducts(newArray);
  };

  // Generate a unique order number
  const generateOrderNumber = () => {
    return `ORD-${Math.floor(Math.random() * 100000)}`;
  };

  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,  // Reference to PrintableInvoice
    documentTitle: "Customer Invoice",
  });

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: () => {
      navigate('/sales_rep/dashboard');
      window.location.reload();
    },
  });

  const handleInvoice = () => {
    if (products.length === 0) {
      alert('No products to preview.');
      return;
    }
  
    // Calculate order details for preview
    const subtotal = calculateTotal();
    const tax = calculateVAT(subtotal);
    const discountPercentage = appliedCoupon ? appliedCoupon.discount : (pointsDet ? pointsDet.discountPercentage : 0);
    const discount = appliedCoupon ? 
      (subtotal + tax) * (discountPercentage / 100) : 
      (pointsDet ? (subtotal + tax) - pointsDet.discountedAmount : 0);
    const total = calculateDiscountedTotal();
  
    const previewOrderDetails = {
      orderNumber: generateOrderNumber(), // Temporary order number for preview
      date: new Date(),
      salesRep: salesRep,
      items: products.map(p => ({
        ...p,
        color: p.selectedColor.color,
        sku: p.selectedColor._id,
        size: p.size,
        quantity: p.quantity,
      })),
      subtotal,
      tax,
      discountAmount: discount,
      discountPercentage,
      total,
      pointsApplied: pointsDet ? true : false,
      couponApplied: appliedCoupon ? true : false
    };
  
    // Set the order state for the invoice preview
    setOrder(previewOrderDetails);
  
    // Slight delay to ensure the order state is set before printing
    setTimeout(() => {
      handlePrintInvoice(); // Call this to trigger the invoice print
    }, 100);
  };
  
const handleCheckout = async () => {
  if (products.length === 0) {
    alert('No products to checkout.');
    return;
  }

  const subtotal = calculateTotal();
  const tax = calculateVAT(subtotal);
  const discountPercentage = appliedCoupon ? appliedCoupon.discount : (pointsDet ? pointsDet.discountPercentage : 0);
  const discount = appliedCoupon ? 
    (subtotal + tax) * (discountPercentage / 100) : 
    (pointsDet ? (subtotal + tax) - pointsDet.discountedAmount : 0);
  const total = calculateDiscountedTotal();

  const paymentType = isTransferModalOpen ? 'Transfer' : 'Credit Card';

  // Make sure each item has color and sku defined before sending to the backend
  const orderItems = products.map(p => ({
    ...p,
    color: p.selectedColor.color,  // Make sure this is a string value
    sku: p.selectedColor._id,      // Make sure this is populated
    size: p.size,                  
    updatedStock: p.selectedColor.amount - p.quantity,
    productid: p.productid,
    measurementId: p.measurementId,
    price: p.price,
    quantity: p.quantity
  }));

  const orderDetails = {
    orderNumber: generateOrderNumber(),
    date: new Date(),
    salesRep: salesRep,
    items: orderItems,
    subtotal,
    tax,
    discountAmount: discount, 
    discountPercentage,
    total,
    payment: transactionDetails,
    pointsApplied: pointsDet ? true : false,
    couponApplied: appliedCoupon ? true : false
  };
  
  console.log("Order Details to Backend:", orderDetails);

  const token = localStorage.getItem('tripstore_login');

  try {
    // Create the sale order
    const response = await createSale(orderDetails, paymentType, token);
    console.log("Create sale response:", response.data);
    
    if (response.data.success === false) {
      throw new Error(response.data.message || 'Error creating sale');
    }
    
    setOrder(orderDetails);
    setIsCheckoutSuccessful(true);

    // Only update stock and print receipt if the sale was successful
    await Promise.all(products.map(async (product) => {
      const { measurementId, selectedColor, quantity, productid } = product;
    
      const stockUpdatePayload = {
        updateData: [
          {
            measurementId,
            colorId: selectedColor._id,
            amount: selectedColor.amount - quantity,
          },
        ],
      };
    
      try {
        const stockResponse = await stockUpdate(token, productid, stockUpdatePayload);
    
        if (stockResponse.status === 200) {
          console.log(`Stock updated successfully for product ${productid}`);
        } else {
          console.error(`Failed to update stock for product ${productid}:`, stockResponse.data);
        }
      } catch (error) {
        console.error(`Error updating stock for product ${productid}:`, error);
      }
    }));

    // Now print receipt since everything was successful
    setTimeout(() => {
      handlePrint();
    }, 100);

    // Clear cart and products
    setCart([]);
    setProducts([]);

  } catch (error) {
    console.error('Error during checkout:', error.response?.data || error.message);
    alert('There was an error processing the sale: ' + (error.message || 'Please try again'));
    setIsCheckoutSuccessful(false);
  }
};

  const handlePaymentClick = (type) => {
    if (type === 'Credit Card') {
      setIsCreditCardModalOpen(true);
    } else if (type === 'Transfer') {
      setIsTransferModalOpen(true);
    } else {
      handleCheckout();
    }
  };

  // Save credit card details and proceed to checkout
  const handleCreditCardSave = () => {
    // Validation
    if (!transactionDetails.transactionNo || !transactionDetails.cardType || 
        !transactionDetails.first6Digits || !transactionDetails.last4Digits) {
      alert('Please fill in all credit card payment details');
      return;
    }
    
    setIsCreditCardModalOpen(false);
    handleCheckout();
  };

  // Save transfer details and proceed to checkout
  const handleTransferSave = () => {
    if (!transactionDetails.transactionNo) {
      alert('Please enter the transaction ID');
      return;
    }
    
    setIsTransferModalOpen(false);
    handleCheckout();
  };

  // Cancel the current sale
  const handleCancelSale = () => {
    setProducts([]);
    setScannedBarcode('');
    navigate('/sales_rep/dashboard');
    window.location.reload();
  };

  // Pause the current sale
  const handlePauseSale = () => {
    const pausedSale = { products, salesRep, orderNumber: generateOrderNumber() };
    localStorage.setItem(`pausedSale-${pausedSale.orderNumber}`, JSON.stringify(pausedSale));
    window.open('/sales_rep/paused-sales', '_blank'); // Open paused sales in a new tab
    navigate('/sales_rep/dashboard'); // Navigate to a fresh sales page
    window.location.reload(); // Reload the current page to reset the state
  };

  const handlePausedSale = () => {
    window.open('/sales_rep/paused-sales'); // Open paused sales in a new tab
    navigate('/sales_rep/dashboard'); // Navigate to a fresh sales page
    window.location.reload(); // Reload the current page to reset the state
  };

  // Handle transaction details change
  const handleTransactionDetailsChange = (e) => {
    setTransactionDetails({
      ...transactionDetails,
      [e.target.name]: e.target.value,
    });
  };

  // Activate coupon
  async function getCouponInfo(coupon) {
    try {
      // Call the activateCoupon function to activate the coupon
      const response = await activateCoupon(coupon);
      
      // Log the response for debugging purposes
      console.log(response.data);
  
      // Check if response data exists and contains coupon details
      if (response.data && response.status === 200) {
        // Clear pointsDet if applying a coupon
        setPointsDet(null);
        setAppliedCoupon(response.data); // Apply the coupon if valid
        alert('Coupon applied successfully!');
      } else {
        // Handle the case where the coupon is invalid or expired
        alert('Invalid or expired coupon. Please try again.');
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error('Error activating coupon:', error);
  
      // Handle the case where an error occurred during activation
      if (error.response) {
        // Display a more specific error message based on the backend's response
        alert(error.response.data.message || 'Invalid or expired coupon. Please try again.');
      } else {
        // Generic error message for network or other unknown issues
        alert('There was an error applying the coupon. Please try again later.');
      }
    }
  }
  
    async function getPointDets(phone, amount) {
      try {
        const response = await checkPointDet(phone, amount);
        if (response.data) {
          // Clear appliedCoupon if applying points
          setAppliedCoupon(null);
          setCoupon('');
          setPointsDet(response.data.data);
          alert('Point Processed successfully!');
        } else {
          alert('Unable to process Points.');
        }
      } catch (error) {
        console.error('Error activating points:', error);
        alert('There was an error applying the points.');
      }
    }
  
    const handleUserPhoneChange = (event) => {
      setUserPhone(event.target.value);
    }
  
    const handleCouponInputChange = (event) => {
      setCoupon(event.target.value);
    };
  
    const isBarcode = (input) => {
      // This function needs to be adjusted based on your actual barcode format
      return input.length === 13 && /^[A-Za-z0-9]{13}$/.test(input);
    };
  
    const handleButtonClick = () => {
      if (appliedCoupon || !coupon) {
        return; // Do nothing if appliedCoupon is already true or coupon is empty
      }
      getCouponInfo(coupon);
    };
  
    const handlePointClick = () => {
      if (!userPhone || userPhone.trim() === '') {
        alert('Please enter a valid phone number');
        return;
      }
      getPointDets(userPhone, (calculateTotal() + calculateVAT(calculateTotal())));
    };
  
    // Handle scanning a product barcode
    const handleScan = async (barcode) => {
      setIsFetching(true); // Disable input
      const baseUrl = API_ENDPOINT();
      try {
        const scannedProduct = await fetchProductInfo(barcode, baseUrl);
  
        if (!scannedProduct) {
          alert('Product not found.');
          setIsFetching(false);
          return;
        }
  
        if (scannedProduct && scannedProduct.selectedColor && scannedProduct.selectedColor.amount < 1) {
          alert('Product out of Stock');
          setIsFetching(false);
          return;
        }
  
        if (scannedProduct.colors && scannedProduct.colors.length > 1) {
          setSelectedProduct(scannedProduct);
          setColorModalOpen(true);
        } else if (scannedProduct.colors && scannedProduct.colors.length === 1) {
          // Single color product, add directly
          addProduct(scannedProduct, scannedProduct.colors[0]);
        } else {
          alert('No color information available for this product');
        }
  
        setScannedBarcode('');
      } catch (error) {
        console.error(error);
        alert('Error fetching product information.');
      } finally {
        setIsFetching(false); // Re-enable input
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };
  
    // Handle manual input of product number
    const handleManualInput = async (productNumber) => {
      setIsFetching(true); // Disable input
      const baseUrl = API_ENDPOINT();
      try {
        const product = await fetchProductInfo(productNumber, baseUrl);
  
        if (!product) {
          alert('Product not found.');
          setIsFetching(false);
          return; // Early return if product is not found
        }
  
        // Handle color selection if multiple colors are available
        if (product.colors && product.colors.length > 1) {
          setSelectedProduct(product);
          setColorModalOpen(true);
        } else if (product.colors && product.colors.length === 1) {
          const selectedColor = product.colors[0];
          if (selectedColor.amount <= 0) {
            alert('Product out of stock');
            setIsFetching(false);
            return;
          }
          // Single color product, add directly
          addProduct(product, selectedColor);
        } else {
          alert('No color information available for this product');
        }
  
        setScannedBarcode(''); // Clear the input after processing
      } catch (error) {
        console.error('Error fetching product information:', error);
        alert('Cannot fetch product information. Please try again.');
      } finally {
        setIsFetching(false); // Re-enable input
        if (inputRef.current) {
          inputRef.current.focus(); // Refocus the input field
        }
      }
    };
  
    // Add product with selected color
    const addProduct = (product, color) => {
      // Ensure product has essential data for the backend
      if (!product.productid || !color._id) {
        console.error('Missing required product data:', product);
        alert('Error: Incomplete product data');
        return;
      }
  
      // Check if the product with the same color already exists
      const existingProductIndex = products.findIndex(
        (p) => p.barcode === product.barcode && p.selectedColor._id === color._id
      );
  
      if (existingProductIndex !== -1) {
        const existingProduct = products[existingProductIndex];
        if (existingProduct.quantity + 1 > color.amount) {
          alert(`Cannot add more than ${color.amount} units of this product.`);
          return;
        }
        setProducts((prevProducts) =>
          prevProducts.map((p, index) =>
            index === existingProductIndex
              ? { ...p, quantity: p.quantity + 1 }
              : p
          )
        );
      } else {
        setProducts((prevProducts) => [
          ...prevProducts,
          { 
            ...product, 
            quantity: 1, 
            selectedColor: color,
            // Ensure these fields are explicitly set for backend validation
            color: color.color,
            sku: color._id
          }
        ]);
      }
    };
  
    // Handle color selection from the modal
    const handleColorSelection = () => {
      if (!selectedColor) {
        alert('Please select a color.');
        return;
      }
      
      if (selectedColor.amount <= 0) {
        alert('Selected color is out of stock');
        return;
      }
  
      addProduct(selectedProduct, selectedColor);
      setSelectedColor(null);
      setSelectedProduct(null);
      setColorModalOpen(false);
    };
  
    // Color Modal Component
    const ColorModalComponent = ({ colors, onSelect }) => {
      if (!colorModalOpen || !selectedProduct) return null;
  
      return (
        <Modal
          isOpen={colorModalOpen}
          onClose={() => {
            setColorModalOpen(false);
            setSelectedProduct(null);
            setSelectedColor(null);
          }}
          onSave={handleColorSelection}
          saveButtonText="Select Color"
        >
          <h2 className="text-xl mb-4">Select Color for {selectedProduct.name}</h2>
          <div className="flex flex-wrap">
            {selectedProduct.colors && selectedProduct.colors.map((color) => (
              <button
                key={color._id}
                className={`flex items-center m-2 p-2 border rounded ${
                  selectedColor && selectedColor._id === color._id
                    ? 'border-black'
                    : 'border-gray-300'
                } ${color.amount <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => {
                  if (color.amount > 0) {
                    setSelectedColor(color);
                  }
                }}
                disabled={color.amount <= 0}
              >
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color.color,
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                  className="mr-2"
                />
                <span>{color.color}</span>
                {color.name && <span className="ml-1">({color.name})</span>}
                <span className="ml-2">Available: {color.amount}</span>
              </button>
            ))}
          </div>
        </Modal>
      );
    };
  
    return (
      <div className="flex flex-col md:flex-row">
        {/* Products Section */}
        <div className="w-full md:w-3/4 p-4">
          {/* Input and Action Buttons */}
          <div className="flex flex-col md:flex-row justify-between items-center w-full mb-4">
            <input
              ref={inputRef}
              type="text"
              value={scannedBarcode}
              onChange={handleInputChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (scannedBarcode) {
                    if (isBarcode(scannedBarcode)) {
                      handleScan(scannedBarcode);
                    } else {
                      handleManualInput(scannedBarcode);
                    }
                  }
                }
              }}
              placeholder="Scan or enter product number..."
              className="p-2 border rounded w-full md:w-3/4"
              disabled={isFetching}
            />
  
            <button
              onClick={handlePauseSale}
              className="my-5 md:my-0 w-full md:w-52 md:ml-2 bg-yellow-500 text-white px-4 py-2 rounded"
            >
              Pause Sale
            </button>
            <button
              onClick={handlePausedSale}
              className="my-5 md:my-0 w-full md:w-52 md:ml-2 bg-green-500 text-white px-4 py-2 rounded"
            >
              Paused Sales
            </button>
            <button
              onClick={handleCancelSale}
              className="w-full md:w-52 md:ml-2 bg-red-500 text-white px-4 py-2 rounded"
            >
              Cancel Sale
            </button>
          </div>
  
          {/* Products Table */}
          <table className="w-full bg-green-100 rounded mb-4">
            <thead>
              <tr>
                <th className="p-2">Item</th>
                <th className="p-2">Size</th>
                <th className="p-2">Color</th>
                <th className="p-2">Price</th>
                <th className="p-2">Qty</th>
                <th className="p-2">Subtotal</th>
                <th className="p-2">Stock</th>
                <th className="p-2">Amount</th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.length === 0 && (
                <tr>
                  <td colSpan="9" className="p-4 text-center">
                    No products added.
                  </td>
                </tr>
              )}
              {products.map((item, index) => (
                <tr key={`${item.barcode}-${item.selectedColor._id}-${index}`} className='text-center'>
                  <td className="p-2">{item.name}</td>
                  <td className="p-2">{item.size}</td>
                  <td className="p-2 flex items-center justify-center">
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: item.selectedColor.color,
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                      className="mr-2"
                    />
                    <span>{item.selectedColor.color}</span>
                  </td>
                  <td className="p-2">₦{formatPrice(item.price)}</td>
                  <td className="p-2">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(e, index)}
                      min="1"
                      max={item.selectedColor.amount}
                      className="w-16 p-1 border rounded"
                    />
                  </td>
                  <td className="p-2">₦{formatPrice(item.price * item.quantity)}</td>
                  <td className='p-2'>{item.selectedColor.amount}</td>
                  <td className='p-2'>{item.selectedColor.amount - item.quantity}</td>
                  <td className="p-2">
                    <button
                      onClick={() => handleReduceQuantity(index)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded"
                    >
                      Reduce
                    </button>
                    <button
                      onClick={() => handleRemoveProduct(index)}
                      className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* Summary and Actions Section */}
        <div className="w-full md:w-1/4 p-4">
          <div className="bg-white p-4 rounded shadow">
            <h1 className="text-xl font-bold mb-2">
              Total:
            {appliedCoupon || pointsDet ? (
              <>
                {appliedCoupon && (
                  <>
                    <span className="line-through text-red-500">
                      ₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}
                    </span>{' '}
                    <span className="text-green-500">
                      ₦{formatPrice(calculateDiscountedTotal())}
                    </span>
                  </>
                )}
  
                {pointsDet && (
                  <>
                    <span className="line-through text-red-500">
                      ₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}
                    </span>{' '}
                    <span className="text-green-500">
                      ₦{formatPrice(calculateDiscountedTotal())}
                    </span>
                  </>
                )}
              </>
            ) : (
              <span>₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}</span>
            )}
            </h1>
            <h1>Subtotal: ₦{formatPrice(calculateTotal())}</h1>
            <h1>VAT (7.5%): ₦{formatPrice(calculateVAT(calculateTotal()))}</h1>
            {appliedCoupon && (
              <h1>
                Discount ({appliedCoupon.discount}%): -₦{formatPrice((calculateTotal() + calculateVAT(calculateTotal())) * (appliedCoupon.discount / 100))}
              </h1>
            )}
            {pointsDet && (
              <h1>
                Points Discount: -₦{formatPrice((calculateTotal() + calculateVAT(calculateTotal())) - pointsDet.discountedAmount)}
              </h1>
            )}
  
            {/* Coupon Section */}
            <div className="mt-4">
              <input 
                type="text"
                id="coupon"
                placeholder='Enter Coupon code' 
                value={coupon}
                onChange={handleCouponInputChange}
                className="w-full p-2 border rounded"
                disabled={appliedCoupon !== null || pointsDet !== null}
              />
              <button 
                onClick={handleButtonClick}
                className={`w-full rounded mt-2 px-4 py-2 ${
                  appliedCoupon || pointsDet ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'
                }`}
                disabled={appliedCoupon !== null || pointsDet !== null}
              >
                Activate Coupon 🎉
              </button>
            </div>
  
            {/* Phone Number Section */}
            <div className="mt-4">
              <input 
                type="text"
                id="phone"
                name='Phone'
                placeholder='User Phone' 
                value={userPhone}
                onChange={handleUserPhoneChange}
                className="w-full p-2 border rounded"
                disabled={pointsDet !== null || appliedCoupon !== null}
              />
              <button 
                onClick={handlePointClick}
                className={`w-full rounded mt-2 px-4 py-2 ${
                  pointsDet || appliedCoupon ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'
                }`}
                disabled={pointsDet !== null || appliedCoupon !== null}
              >
                Check User Points
              </button>
            </div>
  
            {/* Payment Buttons */}
            <div className="mt-6">
            <button
          onClick={handleInvoice}  // Print Invoice only
          className="bg-green-500 text-white w-full py-2 rounded mb-2"
        >
          Print Invoice
        </button>
              <button
                onClick={() => handlePaymentClick('Credit Card')}
                className="bg-green-500 text-white w-full py-2 rounded mb-2"
              >
                Credit Card
              </button>
              <button
                onClick={() => handlePaymentClick('Transfer')}
                className="bg-green-500 text-white w-full py-2 rounded mb-2"
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
  
        {/* Receipt Component (Hidden) */}
        {order && (
          <div className='hidden'>
            <PrintableInvoice ref={invoiceRef} order={order} />
          </div>
        )}
        
        {order && (
          <div className="hidden">
            <PrintableReceipt ref={receiptRef} order={order} />
          </div>
        )}
  
        {/* Credit Card Payment Modal */}
        <Modal
          isOpen={isCreditCardModalOpen}
          onClose={() => setIsCreditCardModalOpen(false)}
          onSave={handleCreditCardSave}
          saveButtonText="Ok"
        >
          <h2 className="text-xl mb-4">Credit Card Payment</h2>
          <div className="mb-4">
            <label className="block mb-2">Transaction No:</label>
            <input
              type="text"
              name="transactionNo"
              onChange={handleTransactionDetailsChange}
              className="p-2 border rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Card Type:</label>
            <input
              type="text"
              name="cardType"
              onChange={handleTransactionDetailsChange}
              className="p-2 border rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">First 6 Digits:</label>
            <input
              type="text"
              name="first6Digits"
              onChange={handleTransactionDetailsChange}
              className="p-2 border rounded w-full"
              maxLength="6"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Last 4 Digits:</label>
            <input
              type="text"
              name="last4Digits"
              onChange={handleTransactionDetailsChange}
              className="p-2 border rounded w-full"
              maxLength="4"
            />
          </div>
        </Modal>
  
        {/* Transfer Payment Modal */}
        <Modal
          isOpen={isTransferModalOpen}
          onClose={() => setIsTransferModalOpen(false)}
          onSave={handleTransferSave}
          saveButtonText="Ok"
        >
          <h2 className="text-xl mb-4">Transfer Payment</h2>
          <div className="mb-4">
            <label className="block mb-2">Transaction ID:</label>
            <input
              type="text"
              name="transactionNo"
              onChange={handleTransactionDetailsChange}
              className="p-2 border rounded w-full"
            />
          </div>
        </Modal>
  
        {/* Color Selection Modal */}
        <ColorModalComponent />
      </div>
    );
  };

export default Sales;

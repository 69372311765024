import React, { useState, useRef, useEffect, useContext } from 'react';
import fetchProductInfo from './fetchProductInfo';
import Modal from './Modal';
import { API_ENDPOINT,  getAllMeasurements, saveInventory } from '../../calls/auths';
import { AppContext } from "../../context/AppContext";
import Loader from '../../calls/Loader';

const StoreKeeping = () => {
  const { user } = useContext(AppContext);
  const [products, setProducts] = useState([]);
  const [scannedBarcode, setScannedBarcode] = useState('');

  const [allMeasurement, setAllMeasurement] = useState([]);
  const [openInventorySummary, setOpenInventorySummary] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openInventorySummaryInfo, setOpenInventorySummaryInfo] = useState(null);
  const [savingInventory, setSavingInventory] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const inputRef = useRef();
  const [isFetching, setIsFetching] = useState(false); // To manage input disabling


  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (inputRef.current && !isFetching) {
      inputRef.current.focus();
    }
  }, [inputRef, isFetching]);



  // useEffect(() => {
  //   const fetchMeasurements = async () => {
  //     try {
  //       const response = await getAllMeasurements(); // Replace with your API endpoint
  //       const data = await response.data;
  //       setAllMeasurement(data); // Store all measurements in state
  //     } catch (error) {
  //       console.error("Failed to fetch measurements:", error.message);
  //     }
  //   };

  //   fetchMeasurements();
  // }, []);


  useEffect(() => {
    let timeoutId;

    const fetchMeasurements = async () => {
      try {
        timeoutId = setTimeout(() => {
          setIsLoading(false);
          setError("Timeout: Failed to fetch measurements within 10 minutes.");
        }, 10 * 60 * 1000); // 2 minutes timeout

        const response = await getAllMeasurements(); // Replace with your API call
        const data = response.data;
        clearTimeout(timeoutId); // Clear timeout on successful fetch
        setAllMeasurement(data);
        setIsLoading(false);
      } catch (err) {
        clearTimeout(timeoutId); // Clear timeout on error
        setIsLoading(false);
        setError("Failed to fetch measurements. Please try again.");
      }
    };

    fetchMeasurements();

    return () => {
      clearTimeout(timeoutId); // Cleanup timeout on component unmount
    };
  }, []);

   // Calculate total, VAT, and discounted total
  //  const calculateTotal = () => {
  //   return products.reduce((total, product) => total + product.price * product.quantity, 0);
  // };

  // const calculateVAT = (total) => {
  //   return total * 0.075;
  // };

  // const calculateDiscountedTotal = () => {
  //   const total = calculateTotal();
  //   const vat = calculateVAT(total);
  //   const grandTotal = total + vat;
  //   if (appliedCoupon) {
  //     const discountAmount = grandTotal * (appliedCoupon.discount / 100);
  //     return grandTotal - discountAmount;
  //   }

  //   if (pointsDet) {
  //     return pointsDet.discountedAmount
  //   }
  //   return grandTotal;
  // };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setScannedBarcode(e.target.value);
  };

  // Handle quantity change in the table
  // const handleQuantityChange = (e, index) => {
  //   const newQuantity = parseInt(e.target.value, 10);
  //   if (newQuantity < 1) return;
  //   const product = products[index];
  //   const availableStock = product.selectedColor.amount;

  //   if (newQuantity > availableStock) {
  //     alert(`Cannot increase the Product quantity more than (${availableStock}) available in stock  for this color.`);
  //     return;
  //   }

  //   setProducts(products.map((p, i) =>
  //     i === index ? { ...p, quantity: newQuantity } : p
  //   ));
  // };

  // Handle reducing quantity by 1
  // const handleReduceQuantity = (index) => {
  //   setProducts(products.map((p, i) =>
  //     i === index ? { ...p, quantity: p.quantity - 1 } : p
  //   ).filter((p) => p.quantity > 0));
  // };

//   const handleFinishStuff = (product) => {
    
//     console.log(product)
//   }

const handleFinishStuff = (product) => {
    let response;
  
    if (product.quantity === product.selectedColor.amount) {
      response = {
        name: product.name,
        size: product.size,
        status: 'balanced',
        message: `Stock Balanced`,
        clr: product.selectedColor.color,
        color: "success",
      };
    } else if (product.quantity < product.selectedColor.amount) {
      const unaccounted = Number(product.selectedColor.amount) - Number(product.quantity);
      // console.log(
      //   `Stock Not Balanced: ${unaccounted} left unaccounted. Amount in Inventory is: ${product.selectedColor.amount}`
      // );
  
      response = {
        name: product.name,
        size: product.size,
        message: `Stock Not Balanced: ${unaccounted} left unaccounted. Amount in Inventory is: ${product.selectedColor.amount}`,
        status: 'understocked',
        clr: product.selectedColor.color,
        color: "danger",
      };
    } else {
      const overstocked = Number(product.quantity) - Number(product.selectedColor.amount);
      // console.log(
      //   `Stock Over the Board: ${overstocked} more than available. Amount in Inventory is: ${product.selectedColor.amount}`
      // );
  
      response = {
        name: product.name,
        size: product.size,
        message: `Stock Over the Board: ${overstocked} more than available. Amount in Inventory is: ${product.selectedColor.amount}`,
        status: 'overstocked',
        clr: product.selectedColor.color,
        color: "caution",
      };
    }
  
    //setOpenInventorySummaryInfo(response); // Ensure this function is defined and properly handles the `response` object
    //setOpenInventorySummary(true); // Ensure this toggles the inventory summary display as intended

    return response
  };

  // const overAllFinishStockCount = async () => {
  //   const violate = []
  //   try {
  //     products.map(product => {
  //       console.log(JSON.stringify(product))
  //       const mandem = handleFinishStuff(product);
  //       violate.push(mandem);
  //     });
  //     setSavingInventory(violate);
  //     console.log(savingInventory);
  //   } catch (error) {
  //     console.log(error?.message)
  //   }
  // }


  // const overAllFinishStockCount = async () => {
  //   const violations = [];
  
  //   try {
  //     // Loop through all measurements
  //     allMeasurement.forEach((measurement) => {
  //       const { productName, size, colors, measurementId } = measurement;
  
  //       colors.forEach((color) => {
  //         // Find matching product in the table by measurementId and color
  //         const relatedProduct = products.find(
  //           (p) =>
  //             p.measurementId === measurementId &&
  //             p.selectedColor._id === color._id
  //         );
  
  //         const analyzedProduct = relatedProduct
  //           ? {
  //               name: productName,
  //               size,
  //               quantity: relatedProduct.quantity, // Quantity from table
  //               selectedColor: color, // Color from measurement
  //             }
  //           : {
  //               name: productName,
  //               size,
  //               quantity: 0, // Default to zero if not in the table
  //               selectedColor: color,
  //             };
  
  //         const result = handleFinishStuff(analyzedProduct);
  //         violations.push(result);
  //       });
  //     });
      
  //     const data = {
  //       analyzedBy: `${user.firstname} ${user.lastname}`,
  //       // analyzedAt: new Date(),
  //       user: user._id,
  //       products: violations,
  //     }
  
  //     setSavingInventory(violations); // Store analysis results
  //     //setOpenInventorySummary(true); // Open inventory summary modal
  //     // console.log("Inventory Analysis Results:", violations);
      

  //     const fein = await saveInventory(data);
  //     console.log(fein.data);
  //   } catch (error) {
  //     console.error("Error analyzing stock:", error?.message);
  //   }
  // };

  const overAllFinishStockCount = async () => {
    const violations = [];
  
    try {
      // Loop through all measurements
      allMeasurement.forEach((measurement) => {
        const { productName, size, colors, measurementId } = measurement;
  
        // For each color in the measurement
        colors.forEach((color) => {
          // Skip if color amount is 0
          if (color.amount === 0) return;
  
          // Find if this product+color combination was scanned
          const scannedProduct = products.find(
            (p) => p.measurementId === measurementId && p.selectedColor._id === color._id
          );
  
          // Create analysis object whether scanned or not
          const productToAnalyze = {
            name: productName,
            size,
            quantity: scannedProduct ? scannedProduct.quantity : 0, // 0 if not scanned
            selectedColor: color,
          };
  
          const result = handleFinishStuff(productToAnalyze);
          violations.push(result);
        });
      });
      
      const data = {
        analyzedBy: `${user.firstname} ${user.lastname}`,
        user: user._id,
        products: violations,
      }
  
      setSavingInventory(violations);
      const fein = await saveInventory(data);
      console.log(fein.data);
    } catch (error) {
      console.error("Error analyzing stock:", error?.message);
    }
  };
  
  // Replace handleWrapUp with overAllFinishStockCount
  const handleWrapUp = overAllFinishStockCount;


  // const handleWrapUp = async () => {
  //   const violate = []
  //   try {
  //     products.map(product => {
  //       const mandem = handleFinishStuff(product);
  //       violate.push(mandem);
  //     });
  //     const data = {
  //       analyzedBy: `${user.firstname} ${user.lastname}`,
  //       // analyzedAt: new Date(),
  //       user: user._id,
  //       products: violate,
  //     }
  //     setSavingInventory(violate);
  //     const fein = await saveInventory(data);
  //     console.log(fein.data);
  //   } catch (error) {
  //     console.log(error?.message)
  //   }
  // }

  // const handleWrapUp = async () => {
  //   try {
  //     const finishIng = handleFinishStuff(products);
  //     const data = {
  //       analyzedBy: `${user.firstname} ${user.lastname}`,
  //       // analyzedAt: new Date(),
  //       user: user._id,
  //       products: finishIng,
  //     }

  //     setSavingInventory(finishIng);
  //     const fein = await saveInventory(data);
  //     console.log(fein.data);
  //   } catch (error) {
  //     console.error("Error analyzing stock:", error?.message);
  //   }
  // }

  const isBarcode = (input) => {
    return input.length === 13 && input.startsWith('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
  };

  // Handle scanning a product barcode
  const handleScan = async (barcode) => {
    setIsFetching(true); // Disable input
    const baseUrl = API_ENDPOINT();
    try {
      const scannedProduct = await fetchProductInfo(barcode, baseUrl);

      if (!scannedProduct) {
        alert('Product not found.');
        setIsFetching(false);
        return;
      }

      if (scannedProduct.colors.length > 1) {
        setSelectedProduct(scannedProduct);
        setColorModalOpen(true);
      } else {
        // Single color product, add directly
        addProduct(scannedProduct, scannedProduct.colors[0]);
      }

      setScannedBarcode('');
    } catch (error) {
      console.error(error);
      alert('Error fetching product information.');
    } finally {
      setIsFetching(false); // Re-enable input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Handle manual input of product number
  const handleManualInput = async (productNumber) => {
    setIsFetching(true); // Disable input
    const baseUrl = API_ENDPOINT();
    try {
      const product = await fetchProductInfo(productNumber, baseUrl);

      if (!product) {
        alert('Product not found.');
        setIsFetching(false);
        return;
      }

      if (product.colors.length > 1) {
        setSelectedProduct(product);
        setColorModalOpen(true);
      } else {
        // Single color product, add directly
        addProduct(product, product.colors[0]);
      }

      setScannedBarcode('');
    } catch (error) {
      console.error(error);
      alert('cant fetching product information.');
    } finally {
      setIsFetching(false); // Re-enable input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Add product with selected color
  const addProduct = (product, color) => {
    if (color.amount === 0) {
      window.alert(`Product: ${product.name}.... size: ${product.size}.... color: ${color.color} unavailable!!`);
      return;
    }
    // Check if the product with the same color already exists
    const existingProductIndex = products.findIndex(
      (p) => p.barcode === product.barcode && p.selectedColor._id === color._id
    );
  
    if (existingProductIndex !== -1) {
      const existingProduct = products[existingProductIndex];
      if (existingProduct.quantity + 1 > color.amount) {
        alert(`Cannot add more than ${color.amount} units of this product.`);
        return;
      }
      // Update the product quantity
      setProducts((prevProducts) =>
        prevProducts.map((p, index) =>
          index === existingProductIndex
            ? { ...p, quantity: p.quantity + 1 }
            : p
        )
      );
    } else {
      // Add a new product to the list if it doesn't exist
      setProducts((prevProducts) => [
        ...prevProducts,
        { ...product, quantity: 1, selectedColor: color }
      ]);
    }
  };
  

  // Handle color selection from the modal
  const handleColorSelection = () => {
    if (!selectedColor) {
      alert('Please select a color.');
      return;
    }
    addProduct(selectedProduct, selectedColor);
    setSelectedColor(null);
    setSelectedProduct(null);
    setColorModalOpen(false);
  };

  // Color Modal Component
  const ColorModalComponent = () => {
    if (!colorModalOpen) return null;

    return (
      <Modal
        isOpen={colorModalOpen}
        onClose={() => {
          setColorModalOpen(false);
          setSelectedProduct(null);
        }}
        onSave={handleColorSelection}
        saveButtonText="Select Color"
      >
        <h2 className="text-xl mb-4">Select Color</h2>
        <div className="flex flex-wrap">
          {selectedProduct.colors.map((color) => (
            <button
              key={color._id}
              className={`flex items-center m-2 p-2 border rounded ${
                selectedColor && selectedColor._id === color._id
                  ? 'border-black'
                  : 'border-gray-300'
              }`}
              onClick={() => setSelectedColor(color)}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: color.color,
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
                className="mr-2"
              />
              <span>{color.color}</span>
            </button>
          ))}
        </div>
      </Modal>
    );
  };

  const handleRefresh = () => {
    setIsLoading(true);
    setError(null);
    setAllMeasurement(null);
    window.location.reload();
  };

  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <button onClick={handleRefresh}>Refresh</button>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row">
      {/* Products Section */}
      <div className="w-full md:w-3/4 p-4">
        {/* Input and Action Buttons */}
        <div className="flex flex-col md:flex-row justify-between items-center w-full mb-4">
          <input
            ref={inputRef}
            type="text"
            value={scannedBarcode}
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (scannedBarcode) {
                  if (isBarcode(scannedBarcode)) {
                    handleScan(scannedBarcode);
                  } else {
                    handleManualInput(scannedBarcode);
                  }
                }
              }
            }}
            placeholder="Scan or enter product number..."
            className="p-2 border rounded w-full md:w-3/4"
            disabled={isFetching}
          />
          <button
            onClick={handleWrapUp}
            className="my-5 md:my-0 w-full md:w-52 md:ml-2 bg-green-500 text-white px-4 py-2 rounded"
          >
            Finish
          </button>
        </div>

        {/* Products Table */}
        <table className="w-full bg-green-100 rounded mb-4">
          <thead>
            <tr>
              <th className="p-2">Item</th>
              <th className="p-2">Size</th>
              <th className="p-2">Color</th>
              <th className="p-2">Price</th>
              <th className="p-2">Count</th>
            </tr>
          </thead>
          <tbody>
            {products.length === 0 && (
              <tr>
                <td colSpan="8" className="p-4 text-center">
                  No products added.
                </td>
              </tr>
            )}
            {products.map((item, index) => (
              <tr key={`${item.barcode}-${item.selectedColor._id}`} className='text-center'>
                <td className="p-2">{item.name}</td>
                <td className="p-2">{item.size}</td>
                <td className="p-2 flex items-center justify-center">
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: item.selectedColor.color,
                      borderRadius: '50%',
                      display: 'inline-block',
                    }}
                    className="mr-2"
                  />
                </td>
                <td className="p-2">₦{formatPrice(item.price)}</td>
                <td className='p-2'>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Summary and Actions Section */}
      <div className="w-full md:w-1/4 p-4 max-h-[90dvh] overflow-y-auto">
        <div className="bg-white p-4 rounded shadow">
          <h1 className="text-xl font-bold mb-2">
            Inventory Summary
          </h1>

          <div className="mt-4">
            {
              savingInventory.map((inventory, i) => (
                <div key={i} className="border-b-4 border-dashed border-b-black pb-2 mb-4">
                  <h4>Product: <b>{inventory.name}</b></h4>
                  <h4>
                      Size: <b>{inventory.size}</b> &nbsp;&nbsp;&nbsp; Color: &nbsp;
                      <div
                      style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: inventory.clr,
                          borderRadius: '50%',
                          display: 'inline-block',
                      }}
                      className="mr-2"
                      />{' '}
                      {inventory.clr}
                  </h4>{' '}
                  <p
                      className={`${
                      inventory.color === 'danger' ? 'text-red-500' : ''
                      } ${
                      inventory.color === 'success' ? 'text-green-500' : ''
                      } ${
                      inventory.color === 'caution' ? 'text-yellow-500' : ''
                      }`}
                  >
                      <span style={{ color: 'black' }}>Msg: </span>{inventory.message}
                  </p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <Modal
        isOpen={openInventorySummary}
        onClose={() => setOpenInventorySummary(false)}
        saveButtonText="Ok"
        >
        <h2 className="text-xl mb-4">Inventory Summary</h2>
        {openInventorySummaryInfo ? (
            <div style={{ textAlign: 'center' }}>
            <h4>Product: {openInventorySummaryInfo.name}</h4> <br />
            <h4>
                Size: {openInventorySummaryInfo.size} &nbsp;&nbsp;&nbsp; Color: &nbsp;&nbsp;&nbsp;
                <div
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: openInventorySummaryInfo.clr,
                    borderRadius: '50%',
                    display: 'inline-block',
                }}
                className="mr-2"
                />{' '}
                {openInventorySummaryInfo.clr}
            </h4>{' '}
            <br />
            <p
                className={`${
                openInventorySummaryInfo.color === 'danger' ? 'text-red-500' : ''
                } ${
                openInventorySummaryInfo.color === 'success' ? 'text-green-500' : ''
                } ${
                openInventorySummaryInfo.color === 'caution' ? 'text-yellow-500' : ''
                }`}
            >
                {openInventorySummaryInfo.message}
            </p>
            </div>
        ) : (
            <div style={{ textAlign: 'center' }}>
            <p className="text-gray-500">No inventory information available.</p>
            </div>
        )}
        </Modal>


      {/* Color Selection Modal */}
      <ColorModalComponent />
    </div>
  );
};

export default StoreKeeping;

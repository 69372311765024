/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getZaddyOrder, getPaystackKey, updateZaddyOrder } from './calls/auths';
import { useParams } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import { FaWhatsapp, FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';

const InvoiceTable = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({
    username: 'loading...',
    items: [],
    status: '',
    totalAmount: '',
    shippingTitle: '',
    shippingPrice: '',
    shippingAddress: '',
    billingAddress: '',
    paymentMethod: '',
    orderDate: '',
  });

  const { username, items, status, totalAmount, shippingTitle, shippingPrice, shippingAddress, billingAddress, paymentMethod, orderDate } = order;

  const [email, setEmail] = useState('');

  function handleChange(e) {
    setEmail(e.target.value);
  }

  const calculateSubtotal = () => {
    return items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
  };

  const shipping = { shipping: { price: shippingPrice } };
  const subtotal = calculateSubtotal();
  const totalInKobo = (totalAmount + parseInt(shippingPrice)) * 100;
  // const totalInKobo = (subtotal + shipping.shipping.price) * 100; // Convert to kobo
  const key = getPaystackKey();

  // (totalAmount + parseInt(shippingPrice))

  const config = {
    reference: (new Date()).getTime().toString(),
    email: email,
    amount: totalInKobo, // Amount in kobo
    publicKey: key,
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    handleSuccess();
  };

  const onClose = () => {};

  async function getOrderInfo() {
    const response = await getZaddyOrder(id);
    setOrder(response.data);
    // console.log(response.data)
  }

  useEffect(() => {
    getOrderInfo();
  }, []);

  if (!order) {
    return <div>Loading...</div>;
  }

  async function handleSuccess() {
    try {
      const response = await updateZaddyOrder(id);
      if (response.status === 200) {
        window.location.reload();
      } else {
        alert(`Order update failed: ${response.data.message}`);
      }
    } catch (error) {
      // console.error('Error updating order:', error);
      alert('Error updating order, please try again later.');
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    initializePayment({ onSuccess, onClose });
  }

  function copyToClipboard() {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Invoice link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', "err");
    });
  }

  function shareToWhatsApp() {
    const currentUrl = window.location.href;
    window.open(`https://wa.me/?text=${encodeURIComponent(currentUrl)}`, '_blank');
  }

  function shareToInstagram() {
    const currentUrl = window.location.href;
    // Instagram does not support direct URL sharing, so we just copy the link
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Invoice link copied to clipboard! Share it on Instagram.');
    }).catch(err => {
      console.error('Failed to copy: ', "err");
    });
  }

  function shareToFacebook() {
    const currentUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`, '_blank');
  }

  function shareToTwitter() {
    const currentUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank');
  }
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Order Details</h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Order #{order._id}</div>
          <p className="text-gray-700 text-base mb-4">{new Date(orderDate).toLocaleDateString()}</p>
          <p className="text-gray-700 text-base"><strong>Customer:</strong> {username}</p>
          <p className="text-gray-700 text-base"><strong>Billing Address:</strong> {billingAddress}</p>
          <p className="text-gray-700 text-base"><strong>Shipping Address:</strong> {shippingAddress}</p>
          <p className="text-gray-700 text-base"><strong>Payment Method:</strong> {paymentMethod}</p>
          <p className="text-gray-700 text-base"><strong>Status:</strong> {status}</p>
        </div>
        <div className="flex p-6 items-center">
          <button className="bg-gray-500 text-white p-2 rounded mr-2" onClick={copyToClipboard}>Copy Invoice Link</button>
          <button className="bg-green-500 text-white p-2 rounded mr-2" onClick={shareToWhatsApp}>
            <FaWhatsapp size={20} />
          </button>
          <button className="bg-pink-500 text-white p-2 rounded mr-2" onClick={shareToInstagram}>
            <FaInstagram size={20} />
          </button>
          <button className="bg-blue-700 text-white p-2 rounded mr-2" onClick={shareToFacebook}>
            <FaFacebook size={20} />
          </button>
          <button className="bg-blue-400 text-white p-2 rounded mr-2" onClick={shareToTwitter}>
            <FaTwitter size={20} />
          </button>
      </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {items.map((item, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img className="w-full h-[30rem] object-cover" src={item.productDetails.images[0]} alt={item.productName} />
            <div className="p-6">
              <h2 className="font-bold text-xl mb-2">{item.productName} ({item.colorName})</h2>
              <p className="text-gray-700 text-base mb-4">
                {item.discount ? (
                    <span className='text-secondary'>
                        <span style={{ textDecoration: 'line-through' }}>&#8358;{item.price.toLocaleString()}</span>
                        {' '}
                        &#8358;{(item.price - (item.price * (item.discount / 100))).toLocaleString()}
                        <p className="text-green-500">{item.discount}% OFF!</p>
                    </span>
                ) : (
                    <span className='text-secondary'>&#8358;{item.price.toLocaleString()}</span>
                )}
              </p>
              <p className="text-gray-700 text-base mb-4">Quantity: {item.quantity}</p>
              <p className="text-gray-700 text-base mb-4">Total: &#8358; {(item.price * item.quantity).toLocaleString()}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-6 p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold text-xl">Summary</h2>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span>Subtotal:</span>
          <span>&#8358; {totalAmount.toLocaleString()}</span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span>Shipping ({shippingTitle}):</span>
          <span>&#8358; {parseInt(shippingPrice).toLocaleString()}</span>
        </div>
        <div className="flex justify-between items-center font-bold text-lg">
          <span>Grand Total:</span>
          <span>&#8358; {(totalAmount + parseInt(shippingPrice)).toLocaleString()}</span>
        </div>
        <div className="mt-6">
          {status === 'Unpaid' && (
            <form onSubmit={handleFormSubmit} action="">
              <label htmlFor="email" className="block mb-2">Zaddy Email</label>
              <input className="w-full p-2 border border-gray-300 rounded mb-4" required type='email' value={email} onChange={handleChange} />
              <button className="w-full bg-blue-500 text-white p-2 rounded">Pay Now</button>
            </form>
          )}
          {status === 'Paid' && <div className="text-green-500 font-bold text-lg">Paid</div>}
          {status === 'Expired' && <div className="text-red-500 font-bold text-lg">Expired</div>}
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;

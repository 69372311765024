import React, { forwardRef } from 'react';
import MainLogo from '../../assets/ic.png';
import WatermarkLogo from '../../assets/ic.png';

const PrintableReceipt = forwardRef(({ order }, ref) => (
  <div className="p-8">
    <div ref={ref} className="printable-receipt relative bg-white shadow-lg rounded-lg mx-auto my-8" style={{ width: '80mm' }}>
      {/* Watermark Logo */}
      <img src={WatermarkLogo} className="absolute inset-0 opacity-10 w-full h-full object-contain" alt="Watermark" />
      
      {/* Main Logo */}
      <div className="text-center mb-4 pt-4">
        <img src={MainLogo} className="w-32 mx-auto" alt="Main Logo" />
      </div>
      
      {/* Receipt Content */}
      <div className="relative px-4">
        <h2 className="text-xl font-bold mb-2 text-center">Order Receipt</h2>
        <p className="text-center mb-4">Thank you for your purchase!</p>
        
        <div className="mb-4">
          <p><span className="font-semibold">Order Number:</span> {order.orderNumber}</p>
          <p><span className="font-semibold">Order Date:</span> {new Date(order.date).toLocaleDateString()}</p>
          <p><span className="font-semibold">salesRep Name:</span> {order.salesRepName}</p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Items Purchased:</h3>
          <table className="w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="border-b py-1">Item</th>
                <th className="border-b py-1">Qty</th>
                <th className="border-b py-1">Price</th>
                <th className="border-b py-1">Total</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, index) => (
                <tr key={index}>
                  <td className="border-b py-1">{item.name}</td>
                  <td className="border-b py-1">{item.quantity}</td>
                  <td className="border-b py-1">&#8358;{item.price.toLocaleString()}</td>
                  <td className="border-b py-1">&#8358;{(item.price * item.quantity).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <p className="font-semibold text-right">Subtotal: &#8358;{order.subtotal.toLocaleString()}</p>
          <p className="font-semibold text-right">VAT 7.5%: &#8358;{order.tax.toLocaleString()}</p>
          
          {/* Display Discount if Available */}
          {order.discount && (
            <p className="font-semibold text-right">
              Discount ({order.discountPercentage}%): -&#8358;{order.discount.toLocaleString()}
            </p>
          )}
          
          {/* Show the Total */}
          <p className="font-semibold text-right">Total: &#8358;{order.total.toLocaleString()}</p>
        </div>
      </div>
      
      {/* Footer */}
      <div className="text-center mt-4 pb-4">
        <p className="text-sm">If you have any questions about your order, please contact us at support@tripstore.ng</p>
        <p className="text-sm mt-2">Thank you for shopping with us!</p>
      </div>
    </div>
  </div>
));

export default PrintableReceipt;
import React, { useEffect, useState } from 'react'
import { getVendorDashboardSummary } from '../../calls/auths';

const VendorDashboard = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState(null);

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random()   
   * 16)];
    }
    return color;
  }
  
  const dashboardItems = document.querySelectorAll('.dashboard-item');
  dashboardItems.forEach(item => {
    item.style.backgroundColor = getRandomColor();
  });

  const getToken = () => {
    return localStorage.getItem('tripstore_login'); // Retrieve the token from localStorage
  };


  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);
      setError(null);
      const token = getToken();

      try {
        const response = await getVendorDashboardSummary(token);
        setSummary(response.data);
        console.log('API Result:', response);
      } catch (error) {
        setError('An error occurred while fetching Vendor summary');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary();
  }, []);

  if (isLoading) {
    return (
      <div>Loading....</div>
    )
  }


  return (
		<div className="bg-white min-h-screen p-6">
			<h1 className="text-2xl text-center font-semibold mb-3 uppercase">
				Stock
			</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8 items-center">
  <div className="bg-green-700 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
    <h2 className="text-white font-bold">Available Stock</h2>
    <p className="text-4xl font-bold text-white py-2">
      {summary?.uploadedLast30Days?.count?.toLocaleString() || 'N/A'}
    </p>
  </div>
  <div className="bg-yellow-500 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
    <h2 className="text-gray-800 font-bold">Total No of Sold Stock</h2>
    <p className="text-4xl font-bold text-white py-2">
      {summary?.soldLast30Days?.count?.toLocaleString() || 'N/A'}
    </p>
  </div>
  <div className="bg-blue-800 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
    <h2 className="text-white font-bold">Total No of Stock</h2>
    <p className="text-4xl font-bold text-white py-2">
      {summary?.totalProductsEverUploaded?.toLocaleString() || 'N/A'}
    </p>
  </div>
  <div className="bg-gray-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
    <h2 className="text-gray-800 font-bold">Total Cost Price</h2>
    <p className="text-4xl font-bold text-white py-2">
      {summary?.uploadedLast30Days?.totalCostPrice?.toLocaleString() || 'N/A'}
    </p>
  </div>
  <div className="bg-orange-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
    <h2 className="text-gray-800 font-bold">Total Value of Stock</h2>
    <p className="text-4xl font-bold text-white py-2">
      #{summary?.uploadedLast30Days?.totalCostPrice && summary?.soldLast30Days?.totalCostPrice
        ? (summary.uploadedLast30Days.totalCostPrice - summary.soldLast30Days.totalCostPrice).toLocaleString()
		: 'N/A'}
    </p>
  </div>
</div>

		</div>
	);
}

export default VendorDashboard
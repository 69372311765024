/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import { AppContext } from "./context/AppContext";
import {jwtDecode} from "jwt-decode";
import VendorNavs from "./components/vendor/VendorNavs";
import VendorDashboard from "./screens/vendor/VendorDashboard";
import VendorAddProduct from "./screens/vendor/AddProducts";
import Inventory from "./screens/vendor/Inventory";
import Settings from "./screens/Settings"



const Vendor = () => {
    const { setLoggedIn } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [headerTitle, setHeaderTitle] = useState('');
    const [expandedNav, setExpandedNav] = useState(false);

    const handleTitleChange = () => {
        switch (location.pathname) {
            case '/vendor/dashboard':
                setHeaderTitle('Dashboard');
                break;
            case '/vendor/add_product':
                setHeaderTitle('Products');
                break;
            case '/vendor/settings':
                setHeaderTitle('My Profile');
                break;
            default:
                if (location.pathname.includes('/vendor/inventory/detail/')) {
                    setHeaderTitle('Inventory > Detail');
                } else if (location.pathname.includes('/vendor/inventory/edit/')) {
                    setHeaderTitle('Inventory > Edit');
                } else {
                    setHeaderTitle('Vendor');
                }
                break;
        }
    };

    useEffect(() => {
        const loginSession = window.localStorage.getItem("tripstore_login");

        if (!loginSession || loginSession === "") {
            navigate('/vendors'); // Redirect to login if no session
        }
    }, [navigate]);

    useEffect(() => {
        handleTitleChange();
    }, [location.pathname]);

    return (
        <div className="w-full h-screen md:flex relative">
            <VendorNavs expandedNav={expandedNav} setExpandedNav={setExpandedNav} />
            <div onClick={() => setExpandedNav(false)} className={`${expandedNav ? "block" : "hidden"} md:hidden fixed w-screen h-screen z-10`}></div>
            <div className="h-full overflow-y-auto overflow-x-hidden md:flex-1 scrollbar relative">
                <Header setExpandedNav={setExpandedNav} title={headerTitle} setLoggedIn={setLoggedIn} />
                <Routes>
                    <Route element={<VendorDashboard />} path="/dashboard" /> 
                    <Route element={<VendorAddProduct />} path="/add_product" />   
                    <Route element={<Inventory />} path="/inventory/*" />
                    <Route element={<Settings />} path="/settings" />
                </Routes>
            </div>
        </div>
    );
};

export default Vendor;

import React, { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { TiThumbsUp, TiThumbsDown } from "react-icons/ti";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { getReviewsByProduct, submitReview } from "../../calls/auths"; // Import the necessary functions
import { AppContext } from "../../context/AppContext";

const Reviews = ({ productId, initialReviews = [] }) => {
    const { user } = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const [reviews, setReviews] = useState(initialReviews);
    const [newReview, setNewReview] = useState({ rating: 0, comment: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
      user: null,
      product: productId,
    });

    // Fetch reviews on component mount and when productId changes
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("tripstore_login");
        const response = await getReviewsByProduct(productId, token);
        if (response && response.data) {
          setReviews(response.data);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  // Count only approved reviews
  const approvedReviews = reviews.filter((item) => item.is_approved);
  const approvedReviewsCount = approvedReviews.length;

  // Calculate average rating from approved reviews
  const averageRating =
    approvedReviewsCount > 0
      ? (
          approvedReviews.reduce((sum, review) => sum + review.rating, 0) /
          approvedReviewsCount
        ).toFixed(1)
      : 0;

  useEffect(() => {
    if (user) {
      setFormData({ ...formData, user: user._id });
    }
  }, [formData, user]);

  // Toggle expand/collapse
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleRatingClick = (rating) => {
    setNewReview((prev) => ({ ...prev, rating }));
  };

  const handleCommentChange = (e) => {
    setNewReview((prev) => ({ ...prev, comment: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newReview.rating === 0) {
      alert("Please select a rating");
      return;
    }

    setIsSubmitting(true);
    try {
      const rating = newReview.rating;
      const comment = newReview.comment;
      const data = { user: user._id, product: productId, rating, comment };
      const token = localStorage.getItem("tripstore_login");

      const response = await submitReview(data, token);
      if (response && response.data) {
        setReviews([...reviews, response.data]);
        setNewReview({ rating: 0, comment: "" }); // Reset form
        alert(
          "Your review has been submitted and will display after being approved 🎉"
        );
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("There was an error submitting your review. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  // Helper function to check if a user object is valid
  const isValidUser = (user) => {
    return user && typeof user === "object" && user.firstname && user.lastname;
  };

    return (
        <div className="border-t border-b py-4 my-6">
      {/* Review Summary - Always visible */}
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleExpanded}
      >
        <div className="flex items-center gap-2">
          <h3 className="font-medium text-lg">Customer Reviews</h3>
          <span className="text-sm text-gray-500">
            ({approvedReviewsCount})
          </span>

          {approvedReviewsCount > 0 && (
            <div className="flex items-center ml-2">
              <div className="flex">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    className={
                      index < Math.round(averageRating)
                        ? "text-yellow-500"
                        : "text-gray-300"
                    }
                    size={16}
                  />
                ))}
              </div>
              <span className="ml-2 text-sm font-medium">{averageRating}</span>
            </div>
          )}
        </div>

        {expanded ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </div>

      {/* Expandable Review Content */}
      {expanded && (
        <div className="mt-4 transition-all duration-300">
          {/* Review Form */}
          {user ? (
            <form
              onSubmit={handleSubmit}
              className="mb-6 p-4 bg-gray-50 rounded-md"
            >
              <h4 className="text-md font-medium mb-2">Write a Review</h4>
              <div className="flex items-center gap-1 mb-2">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer text-xl ${
                      index < newReview.rating
                        ? "text-yellow-500"
                        : "text-gray-400"
                    }`}
                    onClick={() => handleRatingClick(index + 1)}
                  >
                    <FaStar />
                  </div>
                ))}
                <span className="ml-2 text-sm text-gray-500">
                  {newReview.rating > 0
                    ? `${newReview.rating} stars`
                    : "Select rating"}
                </span>
              </div>
              <textarea
                className="w-full border rounded-md p-3 mb-2 focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none"
                placeholder="Share your experience with this product..."
                value={newReview.comment}
                onChange={handleCommentChange}
                rows={4}
                required
              />
              <button
                type="submit"
                className={`px-4 py-2 rounded-md text-white ${
                  isSubmitting
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit Review"}
              </button>
            </form>
          ) : (
            <div className="mb-6 p-4 bg-gray-50 rounded-md text-center">
              <p className="mb-2">
                You need to be logged in to write a review.
              </p>
              <a
                href="/login"
                className="text-blue-500 hover:text-blue-700 font-medium"
              >
                Login to write a review
              </a>
            </div>
          )}

          {/* Review List */}
          {loading ? (
            <div className="text-center py-4">
              <p>Loading reviews...</p>
            </div>
          ) : approvedReviewsCount === 0 ? (
            <div className="text-gray-500 italic text-center py-6">
              No reviews yet. Be the first to review this product!
            </div>
          ) : (
            <div className="divide-y divide-gray-200">
              {reviews
                .filter((item) => item.is_approved)
                .map((review, index) => (
                  <div key={index} className="py-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="font-medium">
                          {isValidUser(review.user)
                            ? `${review.user.firstname} ${review.user.lastname}`
                            : "Anonymous User"}
                        </div>
                        <div className="flex items-center gap-2 mt-1">
                          <div className="flex">
                            {[...Array(5)].map((_, i) => (
                              <FaStar
                                key={i}
                                className={
                                  i < review.rating
                                    ? "text-yellow-500"
                                    : "text-gray-300"
                                }
                                size={14}
                              />
                            ))}
                          </div>
                          <span className="text-sm text-gray-500">
                            Posted {formatDate(review.createdAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 text-gray-700">{review.comment}</p>

                    {/* Helpful/Unhelpful buttons */}
                    <div className="mt-3 flex items-center gap-4">
                      <button className="flex items-center gap-1 text-xs text-gray-500 hover:text-blue-500">
                        <TiThumbsUp size={16} />
                        <span>Helpful ({review.numHelpful || 0})</span>
                      </button>
                      <button className="flex items-center gap-1 text-xs text-gray-500 hover:text-blue-500">
                        <TiThumbsDown size={16} />
                        <span>Unhelpful ({review.numUnhelpful || 0})</span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Reviews;

// Helper function to format date (replace with your actual date formatting logic)
// const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString(); // Adjust date formatting as per your requirements
// };

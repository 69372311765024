import React, { useContext, useEffect, useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaPinterest } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import { AppContext } from '../../context/AppContext';
import { removeFromWishlist, getWishlistAndProduct, handleAddToCart } from '../../calls/auths';
import { ToastComponent } from '../ToastComponent'; // Adjust the path to where your Toast component is located
import { replacer } from '../product/YouMayLike';

const productStatus = { inStock: 'In stock', outOfStock: 'Out of stock' };

const WishlistMain = ({addToCartFunction}) => {
    const { user, getCartCount, cart, wishlist, setWishlist } = useContext(AppContext);
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        async function fetchWishlist() {
            if (user && user._id) {
                try {
                    const result = await getWishlistAndProduct(user._id);

                    if (!result || !result.data || !result.data.items) {
                        console.error('Error: Unable to fetch wishlist data');
                        return;
                    }

                    const items = result.data.items.map(item => {
                        let totalStock = 0;

                        item.productDetails.measurements.forEach(measurement => {
                            measurement.colors.forEach(color => {
                                totalStock += color.amount;
                            });
                        });

                        return {
                            img: item.productDetails.images[0] || '', // Handle case where there might not be an image
                            title: item.productDetails.name,
                            price: item.productDetails.measurements[0].price,
                            status: totalStock > 0 ? productStatus.inStock : productStatus.outOfStock,
                            productDetails: item.productDetails
                        };
                    });

                    setWishlistProducts(items);
                } catch (error) {
                    console.error('Error fetching wishlist:', error);
                    // Handle error state or show error message
                }
            }
        }
        fetchWishlist();
    }, [user]);

    function addToCart(product) {
        handleAddToCart(user, product, 1).then(() => {
            getCartCount();
            setToastMessage('Product added to cart!');
            setShowToast(true);
        });
    }

    async function removeFromWishlistHandler(product) {
        const userId = user._id;
        const productId = product._id;

        const response = await removeFromWishlist({ userId, productId });
        
        if(response.status === 200) {
            setWishlist(wishlist - 1);
            // Remove the item from wishlistProducts state
            setWishlistProducts(prevProducts => prevProducts.filter(item => item.productDetails._id !== productId));

            setToastMessage('Product removed from wishlist!');
            setShowToast(true);
        }
    }

    return (
      <div className="container py-4">
        <div className="divide-y-[1px] divide-slate-300 border-[1px] border-slate-300 rounded-sm overflow-hidden sm:border-none">
          {Array.isArray(wishlistProducts) &&
            wishlistProducts.map((item, index) => (
              <div
                key={index}
                className="relative flex w-full flex-col sm:flex-row items-center gap-4 text-sm py-2"
              >
                <div className="flex-1">
                  <div className="flex gap-2 items-center">
                    <a
                      href={`/product/${item._id}/${replacer(item.name)}`}
                      className="w-[200px] aspect-square rounded-sm overflow-hidden"
                    >
                      <img
                        src={item.img}
                        className="w-full h-full object-cover"
                        alt={item.title}
                      />
                    </a>
                    <div className="flex-1">
                      {/* <a href={`/product/${item._id}/${replacer(item.name)}`} className="hover:text-primary">{item.title}</a> */}

                      {/* new */}
                      <a
                        href={`/product/${item._id}/${replacer(
                          item?.title || ""
                        )}`}
                        className="hover:text-primary"
                      >
                        {/* new ends here */}
                        
                        {item.title}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="sm:w-[100px]">
                  &#8358;{item.price.toLocaleString()}
                </div>
                <div className="sm:w-[100px]">
                  <div
                    className={`${
                      item.status === productStatus.inStock && "text-green-600"
                    } ${
                      item.status === productStatus.outOfStock && "text-red-500"
                    } text-sm font-semibold`}
                  >
                    {item.status}
                  </div>
                </div>
                <div className="">
                  {item.status === productStatus.inStock && (
                    <button
                      onClick={() => addToCartFunction(item.productDetails)}
                      className="text-sm font-semibold flex flex-nowrap items-center justify-center gap-2 py-2 px-4 text-primary border-[1px] border-primary hover:bg-primary hover:text-white"
                    >
                      <MdOutlineAddShoppingCart />
                      <span>ADD TO CART</span>
                    </button>
                  )}
                  {item.status === productStatus.outOfStock && (
                    <div className="text-sm font-semibold flex flex-nowrap items-center justify-center gap-2 py-2 px-4 border-[1px] border-slate-300 text-slate-300">
                      <MdOutlineAddShoppingCart />
                      <span>ADD TO CART</span>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => removeFromWishlistHandler(item.productDetails)}
                  className="absolute sm:static top-2 right-2 text-lg text-gray-400 hover:text-gray-700 cursor-pointer"
                >
                  <IoCloseOutline />
                </button>
              </div>
            ))}
        </div>
        <div className="hidden sm:block w-full h-[1px] bg-slate-300"></div>
        <div className="mt-4 flex items-center flex-wrap gap-2">
          <div>Share on:</div>
          <a
            href="#/"
            className="w-[30px] aspect-square text-gray-400 border-[1px] border-gray-400 rounded-full hover:text-primary hover:border-primary flex items-center justify-center"
          >
            <FaFacebookF />
          </a>
          <a
            href="#/"
            className="w-[30px] aspect-square text-gray-400 border-[1px] border-gray-400 rounded-full hover:text-primary hover:border-primary flex items-center justify-center"
          >
            <IoLogoTwitter />
          </a>
          <a
            href="#/"
            className="w-[30px] aspect-square text-gray-400 border-[1px] border-gray-400 rounded-full hover:text-primary hover:border-primary flex items-center justify-center"
          >
            <FaInstagram />
          </a>
          <a
            href="#/"
            className="w-[30px] aspect-square text-gray-400 border-[1px] border-gray-400 rounded-full hover:text-primary hover:border-primary flex items-center justify-center"
          >
            <FaPinterest />
          </a>
        </div>
        {showToast && (
          <ToastComponent
            success
            message={toastMessage}
            onClose={() => setShowToast(false)}
          />
        )}
      </div>
    );
}

export default WishlistMain;

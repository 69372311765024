import { NavLink } from "react-router-dom"
import SideNav from "../SideNav"
import { RxDashboard } from "react-icons/rx";
import { IoSettingsOutline, IoBagHandleOutline } from "react-icons/io5";
import { BsBagPlus } from "react-icons/bs";
import { CgSize } from "react-icons/cg";
import { BsTicket } from "react-icons/bs";
import { LuUsers, LuUserPlus, LuMonitor } from "react-icons/lu";
import { TbShoppingCartPin, TbSettingsCode, TbReportMoney, TbCategoryPlus, TbTruckReturn } from "react-icons/tb";
import { GrUserSettings } from "react-icons/gr";
import { SiPowerpages } from "react-icons/si";
import { MdOutlinePointOfSale, MdOutlineLocalGroceryStore, MdOutlinePayments, MdControlPointDuplicate } from "react-icons/md";

const AdminNavs = ({ expandedNav, setExpandedNav }) => {

    return (
      <SideNav expandedNav={expandedNav} setExpandedNav={setExpandedNav}>
        <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">
          MENU
        </div>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/dashboard"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <RxDashboard />
            <span>Dashboard</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-primary" : "")}
          to="/admin/pos"
        >
          <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <MdOutlineLocalGroceryStore />
            <span>POS</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-primary" : "")}
          to="/admin/stockkeeping"
        >
          <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <MdOutlineLocalGroceryStore />
            <span>Stock Keeping</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-primary" : "")}
          to="/admin/sales"
        >
          <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbShoppingCartPin />
            <span>Order List</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) => (isActive ? " text-primary" : "")}
          to={"/admin/zaddylist"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <MdOutlinePayments />
            <span>Zaddy pay</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/receipts"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <MdOutlinePointOfSale />
            <span>Receipts</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/sales_record"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbReportMoney />
            <span>Sales Record</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/dispute"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbTruckReturn />
            <span>Dispute</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/prlist"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuUsers />
            <span>PR Sales</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/finance"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuMonitor />
            <span>Finance</span>
          </div>
        </NavLink>

        <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">
          PRODUCT
        </div>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/add_category"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbCategoryPlus />
            <span>Categories</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/sizes"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <CgSize />
            <span>Sizes</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/add_product"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <BsBagPlus />
            <span>Add Products</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/coupons"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <BsTicket />
            <span>Coupons</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/blackfriday"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <BsTicket />
            <span>Sales Promo</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/inventory"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <IoBagHandleOutline />
            <span>Inventory</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/inventory/vendors"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <IoBagHandleOutline />
            <span>Vendor Inventory</span>
          </div>
        </NavLink>

        <NavLink className={({ isActive }) =>
         isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/reviews"}
        >
          <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <IoBagHandleOutline />
            <span>Reviews</span>
          </div>
        </NavLink>

        <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">
          Users
        </div>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/customers"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <MdControlPointDuplicate />
            <span>Customers Point</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/add_sales_rep"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuUserPlus />
            <span>Add Sales Rep</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/users"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuUsers />
            <span>All Users</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/promoters"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuUsers />
            <span>Promoters</span>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/vendors"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <LuUsers />
            <span>Vendors</span>
          </div>
        </NavLink>

        <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">
          Settings
        </div>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/adverts"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbSettingsCode />
            <span>Promo Banner</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/pages"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <SiPowerpages />
            <span>Pages</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/backends"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <TbSettingsCode />
            <span>UI/Settings</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/fashion_categories"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <IoSettingsOutline />
            <span>Fashion Categories</span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? " text-primary" : " text-gray-800"
          }
          to={"/admin/settings"}
        >
          <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
            <GrUserSettings />
            <span>Profile</span>
          </div>
        </NavLink>
      </SideNav>
    );
}

export default AdminNavs
import React, { useState, useContext, useEffect } from 'react';
import Popup from 'reactjs-popup';
import './adminstyle.css';
import { promoterRegister, getPrSalesByEmail } from '../../calls/auths';
import { AppContext } from '../../context/AppContext';
import Loader from '../../calls/Loader';
import { LuEye } from 'react-icons/lu';

const Promoter = ({ currentUserEmail }) => {
    const { user } = useContext(AppContext);
    const [formData, setFormData] = useState({
        bankname: '',
        accountnumber: '',
        accountname: '',
        twitter: '',
        instagram: '',
        facebook: '',
        linkedin: '',
        tiktok: '',
        snapchat: '',
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email || '',
    });
    const [filteredSales, setFilteredSales] = useState([]);
    const [groupedSales, setGroupedSales] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [isPromoter, setIsPromoter] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const token = window.localStorage.getItem("tripstore_login");

    const handleSubmit = async (e) => {
      e.preventDefault();
      const submitData = { ...formData, status: 'pending' };
  
      setIsLoading(true);
      try {
          console.log("Submitting Data:", submitData);
          console.log("Token:", token);
  
          const response = await promoterRegister(submitData, token);
          console.log("API Response:", response); // Log the response
  
          setIsLoading(false);
  
          if (response?.status === 200 && response?.data) {  
              alert('Registration Successful');
              setIsPromoter(true);
              window.location.reload();
          } else {
              console.error("Unexpected API Response:", response);
              alert('Registration Failed. Please try again later.');
          }
      } catch (error) {
          setIsLoading(false);
          console.error('Error registering promoter:', error);
          alert('An error occurred while registering. Please try again.');
      }
  };

    const formatPrice = (price) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(price);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const openModal = (group) => {
        setSelectedGroup(group);
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setSelectedGroup(null);
        setIsModalOpen(false);
    };

    const groupSalesByMonthAndCoupon = (sales) => {
        const grouped = sales.reduce((acc, sale) => {
            const date = new Date(sale.createdAt);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
            const key = `${sale.couponName}-${monthYear}`;
      
            if (!acc[key]) {
                acc[key] = {
                    fullname: `${sale.promoter.firstname} ${sale.promoter.lastname}`,
                    couponName: sale.couponName,
                    monthYear,
                    status: sale.status,
                    orderNumber: sale.orderNumber,
                    totalAmount: 0,
                    breakdown: [],
                };
            }
      
            acc[key].totalAmount += sale.total;
            acc[key].breakdown.push(sale);
      
            return acc;
        }, {});
      
        return Object.values(grouped);
    };

    useEffect(() => {
        // Check if user is already a promoter
        const checkPromoterStatus = async () => {
            try {
                // You may need to create a separate API call for this if not already available
                const response = await getPrSalesByEmail(user.email);
                
                // If user has sales or promoter status, they are already a promoter
                if (response.status === 200 && response.data.length > 0) {
                    setIsPromoter(true);
                }
            } catch (err) {
                console.error("Error checking promoter status:", err);
            }
        };
        
        if (user?.email) {
            checkPromoterStatus();
        }
        
        const fetchAndFilterSales = async () => {
            try {
                const response = await getPrSalesByEmail(user.email);
                
                if (response.status === 200) {
                    const sales = response.data.map((sale) => ({
                        ...sale,
                        promoter: {
                            firstname: sale.promoter.firstname,
                            lastname: sale.promoter.lastname,
                            email: sale.promoter.email,
                        },
                    }));
          
                    const userSales = sales.filter(
                        (sale) => sale.promoter.email === currentUserEmail
                    );
          
                    setFilteredSales(userSales);
                    const grouped = groupSalesByMonthAndCoupon(userSales);
                    setGroupedSales(grouped);
                } else {
                    setErrors(response.data || "Error fetching promoter sales.");
                }
            } catch (err) {
                setErrors(err.message || "Error fetching promoter sales.");
            }
        };
      
        if (user?.email) {
            fetchAndFilterSales();
        }
    }, [currentUserEmail, user?.email]);       

    return (
        <div className='mt-2 p-2'>
            {!isPromoter && (
                <Popup trigger={<button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">Join Promoter</button>} position="center" modal>
                    <div className='w-full py-16 overflow-y-auto'>
                        <div className="mx-auto w-full">
                            <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white">
                                <p className="text-center text-lg font-medium">Promoter Registration</p>
                                <div className='flex flex-col md:flex-row justify-between items-center'>                    
                                    <div className='flex flex-col'>
                                        <label htmlFor="bankname">Bank Name</label>
                                        <select 
                                            name="bankname" 
                                            id="bankname" 
                                            className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm" 
                                            value={formData.bankname}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Bank</option>
              <option value="Access Bank">Access Bank</option>
              <option value="ALAT by Wema">ALAT by Wema</option>
              <option value="Asharami Synergy Frontiers">Asharami Synergy Frontiers</option>
              <option value="Banco Wallet">Banco Wallet</option>
              <option value="Bank of Industry">Bank of Industry</option>
              <option value="Carbon">Carbon</option>
              <option value="Celullant">Celullant</option>
              <option value="Citibank Nigeria">Citibank Nigeria</option>
              <option value="Coronation Merchant Bank">Coronation Merchant Bank</option>
              <option value="EcoBank">EcoBank</option>
              <option value="Eyowo">Eyowo</option>
              <option value="FBNQuest Merchant Bank">FBNQuest Merchant Bank</option>
              <option value="FCMB">FCMB</option>
              <option value="Fidelity Bank">Fidelity Bank</option>
              <option value="First Bank of Nigeria">First Bank of Nigeria</option>
              <option value="First City Monument Bank">First City Monument Bank</option>
              <option value="Globus Bank">Globus Bank</option>
              <option value="Guaranty Trust Bank">Guaranty Trust Bank</option>
              <option value="Heritage Bank">Heritage Bank</option>
              <option value="Keystone Bank">Keystone Bank</option>
              <option value="Kuda">Kuda</option>
              <option value="Mintyn Bank">Mintyn Bank</option>
              <option value="Moniepoint Microfinance Bank">Moniepoint Microfinance Bank</option>
              <option value="NPF Microfinance Bank">NPF Microfinance Bank</option>
              <option value="OneFi">OneFi</option>
              <option value="Paga">Paga</option>
              <option value="PalmPay">PalmPay</option>
              <option value="Paycom">Paycom</option>
              <option value="Paystack">Paystack</option>
              <option value="Polaris Bank">Polaris Bank</option>
              <option value="Rand Merchant Bank">Rand Merchant Bank</option>
              <option value="Rubies Bank">Rubies Bank</option>
              <option value="Spectra">Spectra</option>
              <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
              <option value="Sterling Bank">Sterling Bank</option>
              <option value="Tangerine">Tangerine</option>
              <option value="Titan Trust Bank">Titan Trust Bank</option>
              <option value="UBA">UBA</option>
              <option value="Union Bank">Union Bank</option>
              <option value="Unity Bank">Unity Bank</option>
              <option value="VFD Microfinance Bank">VFD Microfinance Bank</option>
              <option value="Wema Bank">Wema Bank</option>
              <option value="Zenith Bank">Zenith Bank</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col mx-4'>
                                        <label htmlFor="accountnumber">Account Number</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="accountnumber"
                                                name="accountnumber"
                                                placeholder="Account Number"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.accountnumber}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label htmlFor="accountname">Account Name</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="accountname"
                                                name="accountname"
                                                placeholder="Account Name"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.accountname}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div> 
                                </div>
                                <div className='flex flex-col md:flex-row'>
                                    <div className='flex flex-col'>
                                        <label htmlFor="twitter">Twitter</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="twitter"
                                                name="twitter"
                                                placeholder="Twitter"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.twitter}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col mx-4'>
                                        <label htmlFor="instagram">Instagram</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="instagram"
                                                name="instagram"
                                                placeholder="Instagram"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.instagram}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label htmlFor="facebook">Facebook</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="facebook"
                                                name="facebook"
                                                placeholder="Facebook"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.facebook}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row'>
                                    <div className='flex flex-col'>
                                        <label htmlFor="linkedin">Linkedin</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="linkedin"
                                                name="linkedin"
                                                placeholder="Linkedin"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.linkedin}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col mx-4'>
                                        <label htmlFor="tiktok">Tiktok</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="tiktok"
                                                name="tiktok"
                                                placeholder="Tiktok"
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.tiktok}
                                                onChange={handleInputChange}                                
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label htmlFor="snapchat">Snapchat</label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                id="snapchat"
                                                name="snapchat"
                                                placeholder="Snapchat"                                
                                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                                value={formData.snapchat}
                                                onChange={handleInputChange}                                
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end mt-4">
                                    <button 
                                        type="submit" 
                                        className="bg-black text-white px-4 py-2 rounded"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Saving...' : 'Save'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Popup>
            )}
            
            <div className='rounded-lg border border-gray-200 shadow-md mt-10'>
                <div className='overflow-x-auto rounded-t-lg'>
                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Full Name</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Promoter Code</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Date of Purchase</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Amount (%)</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Amount (NGN)</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">PR Points</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Points (NGN)</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Status</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filteredSales.map((sale, index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">
                                        {sale.promoter.firstname} {sale.promoter.lastname}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{sale.couponName}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{formatDate(sale.createdAt)}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{sale.discountPercentage}%</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{formatPrice(sale.total)}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{sale.promoter.pr_points}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{formatPrice(sale.pr_amount)}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-center">{sale.status}</td>
                                </tr>
                            ))}
                            {filteredSales.length === 0 && (
                                <tr>
                                    <td className="px-4 py-2 text-center" colSpan={8}>
                                        No sales found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {isModalOpen && selectedGroup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Sales Breakdown</h2>
                        <ul>
                            {selectedGroup.breakdown.map((sale, idx) => (
                                <li key={idx} className="mb-2">
                                    <p>Date: {new Date(sale.createdAt).toLocaleDateString()}</p>
                                    <p>Order Number: {sale.orderNumber}</p>
                                    <p>Amount: ₦{formatPrice(sale.total)}</p>
                                </li>
                            ))}
                        </ul>
                        <button
                            className="mt-4 bg-black text-white px-4 py-2 rounded-lg"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Promoter;
import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import { AppContext } from "../context/AppContext";
import { getSliders } from '../calls/auths';
import PopupCard from './PopupCard';

const LandingPage = () => {
  const { user } = useContext(AppContext);
  const isAdmin = user?.user_type === "admin";
  const [slider, setSlider] = useState([]); // Initialize as empty array
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  async function init() {
    try {
      setIsLoading(true);
      const response = await getSliders();
      // Ensure we're setting an array
      const sliderData = Array.isArray(response.data) ? response.data : [];
      setSlider(sliderData);
    } catch (error) {
      console.error("Error fetching sliders:", error);
      setError("Failed to load slider data");
      setSlider([]); // Set empty array on error
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, []);

  function handleDoubleClick() {
    if(!isAdmin) return;
    setPopupVisible(true);
  }

  function handleClosePopup() {
    setPopupVisible(false);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-center h-[800px]">
      <Swiper
        loop
        modules={[Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        className="w-full h-[800px] z-10"
        onDoubleClick={handleDoubleClick}
      >
        {Array.isArray(slider) && slider.length > 0 ? (
          slider.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={`relative w-full h-[800px] flex ${isAdmin ? 'hover:border-4 hover:border-blue-500 hover:border-dashed' : ''}`}>
                <div className="w-full md:w-1/2 h-full bg-cover bg-top" style={{ backgroundImage: `url(${item.image1})` }}></div>
                <div className="hidden md:block w-1/2 h-full bg-cover bg-top" style={{ backgroundImage: `url(${item.image2})` }}></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white p-4 mt-[4rem] md:mt-[12.25rem]">
                  <h1 className="text-xl md:text-5xl font-bold bg-secondary px-2 py-2 rounded-t-xl uppercase">{item.text1}</h1>
                  <p className="text-base md:text-xl bg-secondary p-3 rounded-b-full uppercase">{item.text2}</p>
                  <div className="flex mt-4">
                    <a href={`${item.buttonlink1}`} className="bg-white hover:bg-primary hover:text-white px-6 py-3 rounded-md text-secondary font-semibold text-base md:text-xl mr-12">
                      {item.button1}
                    </a>
                    <a href={`${item.buttonlink2}`} className="bg-white hover:bg-primary hover:text-white px-6 py-3 rounded-md text-secondary font-semibold text-base md:text-xl">
                      {item.button2}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div className="w-full h-full flex items-center justify-center">
              <p>No slider data available</p>
            </div>
          </SwiperSlide>
        )}
      </Swiper>

      {isPopupVisible && <PopupCard onClose={handleClosePopup} sliderData={slider} />}
    </div>
  );
};

export default LandingPage;
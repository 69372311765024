import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { AppContext } from '../context/AppContext';
import { getImagesFromCloudinary, uploadImageFromImageGalleryPopComponent } from '../calls/auths';
import { getCroppedImg } from './getCroppedImg'; // Ensure you have this utility function as shown earlier

const ImageGalleryPopup = ({ isOpen, onClose, onImageSelect, multiSelect, showImage = true }) => {
  const [images, setImages] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sortOption, setSortOption] = useState('latest');
  const { selectedImages, setSelectedImages } = useContext(AppContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState(null);
  
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImages, setCroppedImages] = useState([]);

  const observer = useRef();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedImages([]);
      setHasMore(true);
      setNextCursor(null);
      fetchImages(true);
    }
  }, [isOpen, sortOption]);

  const fetchImages = async (reset = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await getImagesFromCloudinary(reset, nextCursor, sortOption);
      if (reset) {
        setImages(response.data.resources);
      } else {
        setImages((prevImages) => [...prevImages, ...response.data.resources]);
      }
      setNextCursor(response.data.next_cursor);
      setHasMore(!!response.data.next_cursor);
    } catch (error) {
      console.error('Error fetching images:', "error");
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    if (multiSelect) {
      if (selectedImages.includes(image)) {
        setSelectedImages(selectedImages.filter((selectedImage) => selectedImage !== image));
      } else {
        setSelectedImages([...selectedImages, image]);
      }
    } else {
      setSelectedImages([image]);
      onImageSelect([image]);
    }
  };

  const handleDoneClick = () => {
    onImageSelect(selectedImages);
    onClose();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    setCurrentFileIndex(0); // Start with the first file
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropAndUpload = async () => {
    try {
      const currentFile = URL.createObjectURL(selectedFiles[currentFileIndex]);
      const croppedImage = await getCroppedImg(currentFile, croppedAreaPixels);

      // Automatically upload after cropping
      const formData = new FormData();
      formData.append('images', croppedImage);

      setMessage(`Uploading image ${currentFileIndex + 1} of ${selectedFiles.length}, please wait...`);
      const response = await uploadImageFromImageGalleryPopComponent(formData);
      if (response.status === 200) {
        setMessage(`Uploaded image ${currentFileIndex + 1} successfully...`);
      }
      fetchImages(true);
      setSelectedFiles([]);
      setCurrentFileIndex(null); // Reset for next image

      // Proceed to the next file
      if (currentFileIndex < selectedFiles.length - 1) {
        setCurrentFileIndex(currentFileIndex + 1);
      } else {
        // Clear the file input and reset when all images are done
        setSelectedFiles([]);
        setCurrentFileIndex(0);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (error) {
      console.error('Error cropping/uploading image:', "error");
    }
  };

  const lastImageElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchImages();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-4 max-w-3xl w-full h-4/5 overflow-auto relative">
        <div className="sticky top-0 bg-white p-2 z-10">
          <button className="absolute top-2 right-2 text-white ml-3 bg-primary" onClick={handleDoneClick}>
            Done
          </button>
          <button className="absolute top-2 left-2 text-white ml-3 bg-primary" onClick={() => fetchImages()}>
            Reload Image
          </button>
          <br></br>
          <br></br>
          <br></br>
          <h1 className="text-xl mb-4">Images (Selected: {selectedImages.length})</h1>
          <input type="file" multiple onChange={handleFileChange} className="mb-4" ref={fileInputRef}/>
          {selectedFiles.length > 0 && currentFileIndex < selectedFiles.length && (
  <div className="h-[300px] md:h-[450px] flex flex-col">
    <div className="flex-grow">
      <Cropper
        image={URL.createObjectURL(selectedFiles[currentFileIndex])}
        crop={crop}
        zoom={zoom}
        aspect={4 / 4}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        style={{ height: '100%' }} // Ensure the cropper takes full height
      />
    </div>
    <button className="relative bg-black text-white px-4 py-2 rounded mt-0 md:mt-4" onClick={handleCropAndUpload}>
      Crop & Upload
    </button>
  </div>
)}

        </div>
        {message &&
          <div>
            <p className='text-green-500 border border-black'>{message}</p>
            <button onClick={() => setMessage(null)}>Close Message</button>
          </div>
        }

      <div className="flex flex-wrap">
        {showImage ? (
          images.map((image, index) => {
            if (index === images.length - 1) {
              return (
                <img
                  ref={lastImageElementRef}
                  key={image.public_id}
                  src={image.secure_url}
                  alt={image.public_id}
                  onClick={() => handleImageClick(image)}
                  className={`w-1/3 p-1 cursor-pointer ${selectedImages.includes(image) ? 'border-4 border-blue-500' : ''}`}
                />
              );
            } else {
              return (
                <img
                  key={image.public_id}
                  src={image.secure_url}
                  alt={image.public_id}
                  onClick={() => handleImageClick(image)}
                  className={`w-1/3 p-1 cursor-pointer ${selectedImages.includes(image) ? 'border-4 border-blue-500' : ''}`}
                />
              );
            }
          })
        ) : (
          <div className="flex justify-center w-full mt-4">Images are not available for vendors.</div>
        )}
      </div>


{/* {loading && <div className="flex justify-center mt-4">Loading more...</div>}
{!hasMore && !loading && <div className="flex justify-center mt-4">No more images</div>} */}


        {/* <div className="flex flex-wrap">
          {images.map((image, index) => {
            if (index === images.length - 1) {
              return (
                <img
                  ref={lastImageElementRef}
                  key={image.public_id}
                  src={image.secure_url}
                  alt={image.public_id}
                  onClick={() => handleImageClick(image)}
                  className={`w-1/3 p-1 cursor-pointer ${selectedImages.includes(image) ? 'border-4 border-blue-500' : ''}`}
                />
              );
            } else {
              return (
                <img
                  key={image.public_id}
                  src={image.secure_url}
                  alt={image.public_id}
                  onClick={() => handleImageClick(image)}
                  className={`w-1/3 p-1 cursor-pointer ${selectedImages.includes(image) ? 'border-4 border-blue-500' : ''}`}
                />
              );
            }
          })}
        </div> */}

        {/* {loading && <div className="flex justify-center mt-4">Loading more...</div>}
        {!hasMore && !loading && <div className="flex justify-center mt-4">No more images</div>} */}

      {showImage && (
        <>
          {loading && <div className="flex justify-center mt-4">Loading more...</div>}
          {!hasMore && !loading && <div className="flex justify-center mt-4">No more images</div>}
        </>
      )}
      </div>
    </div>
  );
};

export default ImageGalleryPopup;

import { createContext, useEffect, useState } from "react";
import { getUserInfo, getCart, getWishlist, getCartItems, searchResponse, removeFromCart, getVendorInfo } from "../calls/auths"; // Replace with your auth logic
import { useNavigate } from "react-router-dom";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [offset, setOffset] = useState(0);
  const [user, setUser] = useState(null);
  const [newProducts, setNewProducts] = useState([]); // Add newProduct state
  const [catr, setCatr] = useState([]);
  const [cart, setCart] = useState(0); // Add cart state
  const [wishlist, setWishlist] = useState(0); // Add cart state
  const [wishlistItems, setwishlistItems] = useState(); // Add cart state
  const [cartItems, setCartItems] = useState(0); 
  const [productArray, setProductArray] = useState([]); 
  const [cartArray, setCartArray] = useState([]); 
  const [product, setProduct] = useState({});   
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [productInformation, setProductInformation] = useState([]);

  const vendorFare = window.localStorage.getItem("vendor");

  const [currentShipping, setCurrentShipping] = useState(() => {
  const savedShipping = localStorage.getItem('selectedShipping');
    return savedShipping ? JSON.parse(savedShipping) : { index: 0, shipping: shipping[0] };
  });
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    localStorage.setItem('selectedShipping', JSON.stringify(currentShipping));
  }, [currentShipping]);

  useEffect(() => {
    const selectedShipping = localStorage.getItem('selectedShipping');
    if(!selectedShipping) {
      changeShipping(shipping[0])
    }
  }, [])

  const changeShipping = (index) => {
    setCurrentShipping({ index: index, shipping: shipping[index] });
  };

  useEffect(() => {
    const handleLogin = async () => {
      const loginSession = window.localStorage.getItem("tripstore_login");
      const vendor = window.localStorage.getItem("vendor");
  
      if (loginSession && loginSession !== "") {
        try {
          let response;
          if (vendor && vendor === "vendor") {
            response = await getVendorInfo(loginSession);
          } else {
            response = await getUserInfo(loginSession);
          }

          if(response.status === 404) {
            alert('You have been logged out.');
            setLoggedIn(false); // Set to false on error
            setUser(null);
            if (vendor && vendor === "vendor") {
              navigate('/vendors');
            }
            navigate('/login');
            return;
          }
          setUser(response.data);
          setLoggedIn(true);
        } catch (error) {
          console.log('Error during login:', error);
          console.error("Error fetching user info:", error);
  
          // Handle specific error for invalid token
          if (error.response && error.response.data.message === "Invalid token") {
            alert('Your session has expired. You will be logged out.');
          } else {
            alert('An unexpected error occurred. Please try again.');
          }
  
          setLoggedIn(false); // Set to false on error
          setUser(null);
          if (vendor && vendor === "vendor") {
            navigate('/vendors');
          }
          navigate('/login'); // Navigate to /login on error
        }
      } else {
        console.log("Unauthorized access - no session found");
        setLoggedIn(false);
      }
    };
  
    // Handle scroll event
    window.onscroll = () => setOffset(window.scrollY);
  
    // Call the login handler
    handleLogin();
  }, []);
  

  const updatenewProducts = (products) => {
    setNewProducts(products);
  };

  const updateCatrs = (cartier) => {
    setCatr(cartier);
  }

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  // const removeFromCart = (productId) => {
  //   setCart(cart.filter((item) => item._id !== productId));
  // };

  const updateCartItem = (updatedItem) => {
    setCart(cart.map((item) => (item._id === updatedItem._id ? updatedItem : item)));
  };

  async function getWishListCount() {
      if (vendorFare && vendorFare === "vendor") {
        setWishlist(0);
        setwishlistItems(null);
        return;
      }
      const response = await getWishlist(user._id);
      if(response.data != null) {
        setWishlist(response.data.items.length);
        setwishlistItems(response.data.items);
      }
  }

  async function getCartCount() {
    if (user && !vendorFare && vendorFare !== "vendor") {
        const response = await getCart(user._id);
        if (response.data != null) {
            setCartItems(response.data.items);  
            setCart(response.data.items.length);
            setProductArray(response.data.items);
            setCartArray(response.data.items);
        }
    } else {
      const localStorageCart = JSON.parse(localStorage.getItem('cart')) || [];
      if (localStorageCart.length > 0) {
          const transformedCart = localStorageCart.map(item => ({
              productId: item.productId,
              colorId: item.colorId,
              quantity: item.quantity
          }));

          setCartItems(transformedCart);  
          setCart(transformedCart.length);
          setProductArray(transformedCart);
          setCartArray(transformedCart);
      } else {
          setCartItems([]);  
          setCart(0);
          setProductArray([]);
          setCartArray([]);
      }
    }
  }


  async function getCartProductsInformation(data) {
    try {
      // Ensure `data` is an array
      if (!Array.isArray(data)) {
        return;
      }

      // Map through the data if it is an array
      const items = data.map((item) => ({
        productId: item.productId,
        colorId: item.colorId,
        quantity: item.quantity,
      }));

      const response = await getCartItems(items);

      if (response.status === 200) {
        setProductInformation(response.data);
      } else {
        console.error("Failed to fetch cart items:", response);
      }
    } catch (error) {
      console.error("Error in getCartProductsInformation:", error);
    }
  }

  useEffect(() => {
    getCartCount()
  }, [loggedIn])

  useEffect(() => {
    getCartProductsInformation(cartItems);
  }, [cartItems]);

  async function getCartProducts() {
    const response = await searchResponse(product);
    setNewProducts(response.data)
  }

  useEffect(() => {
    const updatedProduct = {};
      productArray.forEach((item, index) => {
      updatedProduct[`id`] = item.product;
    });
    setProduct(updatedProduct);

    getCartProducts()
  }, [productArray]);

  useEffect(() => {
      if(user) {
          getWishListCount();
          getCartCount();
      }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        offset,
        user,
        newProducts,
        updatenewProducts,
        cart,
        setCart,
        addToCart,
        // removeFromCart,
        updateCartItem,
        catr,
        updateCatrs,
        setUser,
        wishlist,
        setWishlist,
        cartItems,
        cartArray,
        setwishlistItems,
        wishlistItems,
        isInWishlist, 
        setIsInWishlist,
        productInformation,
        setProductInformation,
        getCartCount,
        currentShipping,
        setCurrentShipping,
        shipping,
        changeShipping,
        selectedImages,
        setSelectedImages
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

const shipping = [
    { title: 'local', price: 1500 },
    { title: 'standard', price: 12000 },
    { title: 'express', price: 20000 },
];
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../../calls/auths";
import { FaStar } from "react-icons/fa";

const StatusBadge = ({ status }) => {
  const badgeColors = {
    approved: "#28a745",
    pending: "#ffc107",
    declined: "#dc3545",
  };

  return (
    <span
      style={{
        display: "inline-block",
        padding: "0.5em 1em",
        borderRadius: "0.25rem",
        fontSize: "0.875rem",
        fontWeight: 600,
        backgroundColor: badgeColors[status] || "#6c757d",
        color: status === "pending" ? "black" : "white",
      }}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

// Helper function to extract the first price from measurements
const getProductPrice = (product) => {
  if (!product) return "N/A";

  // Check if measurements exist and have items
  if (
    product.measurements &&
    Array.isArray(product.measurements) &&
    product.measurements.length > 0
  ) {
    return product.measurements[0].price || "N/A";
  }

  return "N/A";
};

// Helper function to get product image
const getProductImage = (product) => {
  if (!product) return null;

  // First try defaultImage
  if (product.defaultImage && product.defaultImage !== "") {
    return product.defaultImage;
  }

  // Then try first image from images array
  if (
    product.images &&
    Array.isArray(product.images) &&
    product.images.length > 0
  ) {
    return product.images[0];
  }

  return null;
};

// Helper function to get category name
const getCategoryName = (product) => {
  if (!product || !product.category) return "N/A";

  // If category is an array of objects with name property
  if (Array.isArray(product.category) && product.category.length > 0) {
    // Check if each item has a name property
    if (product.category[0].name) {
      return product.category[0].name;
    }
    // Return the first category as a string if it's a string or has a toString method
    return String(product.category[0]);
  }

  // If category is a single object with a name property
  if (
    typeof product.category === "object" &&
    product.category !== null &&
    product.category.name
  ) {
    return product.category.name;
  }

  // If category is a string
  if (typeof product.category === "string") {
    return product.category;
  }

  return "N/A";
};

const Review = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("tripstore_login");

      if (!token) {
        console.error("No token found, user is not authenticated.");
        return;
      }

      const response = await axios.get(`${API_ENDPOINT()}/api/review`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        const reviewsData = response.data;

        // Grouping reviews by product ID
        const productMap = {};
        reviewsData.forEach((review) => {
          if (review.product && typeof review.product === "string") {
            if (!productMap[review.product]) {
              productMap[review.product] = {
                productId: review.product,
                reviews: [],
                totalReviews: 0,
              };
            }
            productMap[review.product].reviews.push(review);
            productMap[review.product].totalReviews++;
          }
        });

        // Fetch product details for unique products
        const productDetails = await Promise.all(
          Object.keys(productMap).map(async (productId) => {
            try {
              console.log(`Attempting to fetch product with ID: ${productId}`);

              // Add authentication token to product requests
              const productResponse = await axios.get(
                `${API_ENDPOINT()}/api/products/${productId}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );

              // Log the response to debug
              console.log(
                `Product response for ${productId}:`,
                productResponse.data
              );

              // Get the product data
              const productData = productResponse.data;

              return {
                ...productMap[productId],
                product: productData,
                productId: productId,
                // Store raw data for debugging
                rawProductData: productData,
              };
            } catch (error) {
              console.error(`Error fetching product ${productId}:`, error);
              console.error(
                "Error details:",
                error.response ? error.response.data : error.message
              );
              return {
                ...productMap[productId],
                product: { name: "Product ID: " + productId },
                productId: productId,
              };
            }
          })
        );

        console.log("Processed product details:", productDetails);
        setProducts(productDetails);
        setReviews(reviewsData);

        // Update selected reviews if a product is already selected
        if (selectedProduct) {
          setSelectedReviews(
            reviewsData.filter((r) => r.product === selectedProduct.productId)
          );
        }
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductClick = (product) => {
    console.log("Selected product details:", product);
    setSelectedProduct(product);
    setSelectedReviews(reviews.filter((r) => r.product === product.productId));
  };

  // Get current status from review
  const getReviewStatus = (review) => {
    if (review.is_approved) return "approved";
    if (review.is_declined) return "declined";
    return "pending";
  };

  // handleStatusChange function
  const handleStatusChange = async (reviewId, newStatus) => {
    try {
      const token = localStorage.getItem("tripstore_login");

      if (!token) {
        console.error("No token found, user is not authenticated.");
        return;
      }

      let endpoint;

      // Choose the right endpoint based on the new status
      if (newStatus === "approved") {
        endpoint = `${API_ENDPOINT()}/api/review/approve/${reviewId}`;
      } else if (newStatus === "declined") {
        endpoint = `${API_ENDPOINT()}/api/review/decline/${reviewId}`;
      } else {
        // Handle pending status if needed
        console.error("Pending status change not implemented in the API");
        return;
      }

      await axios.put(
        endpoint,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the local state to reflect the change immediately
      setSelectedReviews((prevReviews) =>
        prevReviews.map((review) => {
          if (review._id === reviewId) {
            return {
              ...review,
              is_approved: newStatus === "approved",
              is_declined: newStatus === "declined",
            };
          }
          return review;
        })
      );

      // Refresh all reviews after status change
      fetchReviews();
    } catch (error) {
      console.error(`Error updating review status to ${newStatus}:`, error);
    }
  };

  return (
    <div className="flex">
      <div className="p-6 w-full">
        <h2 className="text-2xl font-semibold mb-4">Product Reviews</h2>

        {/* Product List */}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {products.map((product) => (
              <div
                key={product.productId}
                className="border p-4 bg-white shadow rounded-md cursor-pointer hover:bg-gray-50"
                onClick={() => handleProductClick(product)}
              >
                <strong>{product.product.name || "Unnamed Product"}</strong>
                <p>Total Reviews: {product.totalReviews}</p>
              </div>
            ))}
          </div>
        )}

        {/* Product Details and Reviews */}
        {selectedProduct && (
          <div className="mt-6 p-6 border bg-white shadow rounded-md">
            <div className="flex flex-col md:flex-row gap-6">
              {/* Product Image */}
              <div className="flex-shrink-0">
                {getProductImage(selectedProduct.product) ? (
                  <img
                    src={getProductImage(selectedProduct.product)}
                    alt={selectedProduct.product?.name || "Product Image"}
                    className="w-32 h-32 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-32 h-32 bg-gray-200 rounded-lg flex items-center justify-center">
                    <span className="text-gray-500">No image</span>
                  </div>
                )}
              </div>

              {/* Product Details */}
              <div className="flex-grow">
                <h3 className="text-xl font-bold">
                  {selectedProduct.product?.name || "Unnamed Product"}
                </h3>

                <div className="mt-2">
                  <p>
                    <strong>Category:</strong>{" "}
                    {getCategoryName(selectedProduct.product)}
                  </p>

                  <p>
                    <strong>Description:</strong>{" "}
                    {selectedProduct.product?.description ||
                      "No description available"}
                  </p>

                  <p>
                    <strong>Price:</strong>{" "}
                    {getProductPrice(selectedProduct.product) !== "N/A"
                      ? `$${parseFloat(
                          getProductPrice(selectedProduct.product)
                        ).toFixed(2)}`
                      : "N/A"}
                  </p>

                  <p>
                    <strong>Total Reviews:</strong>{" "}
                    {selectedProduct.totalReviews}
                  </p>
                </div>

                {/* Debug info - remove in production */}
                {process.env.NODE_ENV === "development" && (
                  <details className="mt-4 text-xs">
                    <summary className="text-gray-500 cursor-pointer">
                      Product Debug Info
                    </summary>
                    <pre className="mt-2 p-2 bg-gray-100 rounded overflow-auto max-h-40">
                      {JSON.stringify(
                        selectedProduct.rawProductData ||
                          selectedProduct.product,
                        null,
                        2
                      )}
                    </pre>
                  </details>
                )}
              </div>
            </div>

            <h4 className="text-lg font-semibold mt-6">Reviews</h4>
            {selectedReviews.length > 0 ? (
              selectedReviews.map((review) => (
                <div
                  key={review._id}
                  className="border p-4 bg-white shadow rounded-md mt-4"
                >
                  <p>
                    <strong>User:</strong> {review.user?.firstname}{" "}
                    {review.user?.lastname} ({review.user?.email})
                  </p>
                  <div className="flex items-center gap-1">
                    <strong>Rating:</strong>
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        className={
                          index < review.rating
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }
                      />
                    ))}
                  </div>
                  <p>
                    <strong>Comment:</strong> {review.comment}
                  </p>

                  <div className="mt-2">
                    <strong>Status: </strong>
                    <StatusBadge status={getReviewStatus(review)} />
                  </div>
                  {/* Approve/Decline Dropdown */}
                  <div className="mt-4">
                    <label className="block font-semibold mb-1">Action:</label>
                    <select
                      onChange={(e) =>
                        handleStatusChange(review._id, e.target.value)
                      }
                      value={getReviewStatus(review)}
                      className="border rounded px-3 py-2 w-full cursor-pointer"
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="declined">Declined</option>
                    </select>
                  </div>
                </div>
              ))
            ) : (
              <p>No reviews for this product.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
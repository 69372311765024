import React, { forwardRef } from 'react';
import MainLogo from '../../assets/ic.png';
import WatermarkLogo from '../../assets/ic.png';

const PrintableInvoice = forwardRef(({ order }, ref) => {
  if (!order) {
    console.error('No order data provided to PrintableInvoice');
    return null;
  }

  // Helper function to safely format currency
  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    return !isNaN(num) ? num.toLocaleString() : '0';
  };

  // Helper function to safely get item total
  const getItemTotal = (item) => {
    const price = parseFloat(item.price) || 0;
    const quantity = parseInt(item.quantity) || 0;
    return price * quantity;
  };

  // Helper function to get payment details
  const getPaymentDetails = () => {
    if (!order.payment) return 'Not Specified';
    return order.payment.type || 'Not Specified';
  };

  // Calculate totals
  const subtotal = order.subtotal || 0;
  const tax = order.tax || 0;
  const discount = order.discount || order.discountAmount || 0;
  const total = order.total || 0;

  return (
    <div ref={ref} className="printable-receipt relative bg-white shadow-lg rounded-lg mx-auto my-8" style={{ width: '80mm' }}>
      {/* Watermark Logo */}
      <img src={WatermarkLogo} className="absolute inset-0 opacity-10 w-full h-full object-contain" alt="Watermark" />
      
      {/* Main Logo */}
      <div className="text-center mb-4 pt-4">
        <img src={MainLogo} className="w-32 mx-auto" alt="Main Logo" />
      </div>
      
      {/* Receipt Content */}
      <div className="relative px-4">
        <h2 className="text-xl font-bold mb-2 text-center">Order Invoice</h2>
        <p className="text-center">Not Paid</p>
        <p className="text-center italic">Kindly Pay into the Account Details Below</p>
        <p className="text-center">Moniepoint Microfinance Bank</p>
        <p className="text-center font-semibold">TRIP FASHION - TRIPSTORE</p>
        <p className="text-center font-semibold">5705682677</p>
        <p className="text-center font-semibold">5705682622</p>
        
        <div className="mb-4">
          <p><span className="font-semibold">Order Number:</span> {order.orderNumber || 'N/A'}</p>
          <p>
            <span className="font-semibold">Order Date:</span>{' '}
            {order.date ? new Date(order.date).toLocaleDateString() : 'N/A'}
          </p>
          <p><span className="font-semibold">Sales Rep:</span> {order.salesRepName || 'N/A'}</p>
          <p><span className="font-semibold">Payment Method:</span> {getPaymentDetails()}</p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Items Purchased:</h3>
          <table className="w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="border-b py-1">Item</th>
                <th className="border-b py-1">Qty</th>
                <th className="border-b py-1">Price</th>
                <th className="border-b py-1">Total</th>
              </tr>
            </thead>
            <tbody>
              {(order.items || []).map((item, index) => (
                <tr key={index}>
                  <td className="border-b py-1">{item.name || item.product || 'N/A'}</td>
                  <td className="border-b py-1">{item.quantity || 0}</td>
                  <td className="border-b py-1">₦{formatCurrency(item.price)}</td>
                  <td className="border-b py-1">₦{formatCurrency(getItemTotal(item))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <p className="font-semibold text-right">
            Subtotal: ₦{formatCurrency(subtotal)}
          </p>
          <p className="font-semibold text-right">
            VAT 7.5%: ₦{formatCurrency(tax)}
          </p>
          
          {/* Display Discount if Available */}
          {discount > 0 && (
            <p className="font-semibold text-right">
              Discount ({order.discountPercentage || 0}%): -₦{formatCurrency(discount)}
            </p>
          )}
          
          {/* Show the Total */}
          <p className="font-semibold text-right border-t mt-2 pt-2">
            Total: ₦{formatCurrency(total)}
          </p>
        </div>

        {/* Transaction ID if available */}
        {order.payment?.details?.transactionId && (
          <div className="mt-2 text-center">
            <p className="text-sm">
              Transaction ID: {order.payment.details.transactionId}
            </p>
          </div>
        )}
        
        {/* Footer */}
        <div className="text-center mt-4 pb-4">
          <p className="text-sm">
            If you have any questions about your order,
            <br />please contact us at support@tripstore.ng
          </p>
          <p className="text-sm mt-2">Thank you for shopping with us!</p>
        </div>
      </div>
    </div>
  );
});

PrintableInvoice.displayName = 'PrintableInvoice';

export default PrintableInvoice;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import fetchProductInfo from "./fetchProductInfo";
import "./adminstyle.css"; // Import the CSS file
import { FiSearch } from "react-icons/fi"; // Import the icon
import {
  createDispute,
  getAllDispute,
  getAllSales,
  getSaleByOrderNumber,
  API_ENDPOINT,
  updateProductStatus,
  deleteDispute,
  addReturnedProductStock,
  removeExchangedProductStock,
  createSale,
  stockUpdate,
} from "../../calls/auths";
import Pagination from "./Pagination";
import { useReactToPrint } from 'react-to-print';
import PrintableReceipt from './PrintableReceipt';
// import axios from "axios";

const Dispute = () => {
  const [formData, setFormData] = useState({
    receipt_no: '',
    order_id: '',
    dos: '',
    dod: new Date().toISOString().split('T')[0],
    products: []
  });

  const [form1Data, setForm1Data] = useState({
    receipt_no: "",
    reason: "",
    dos: "",
    dod: "",
    balance: "",
    salesRepId: "",
  });

  const [disputedItems, setDisputedItems] = useState([]);
  const [exchangedItems, setExchangedItems] = useState([]);
  const [currentExchangeIndex, setCurrentExchangeIndex] = useState(0);

  const handleAddDisputedItem = () => {
    setDisputedItems([
      ...disputedItems,
      {
        sku_no: "",
        product_name: "",
        color: "",
        size: "",
        price: 0,
        quantity: 1,
      },
    ]);
  };

  const handleAddExchangedItem = () => {
    setExchangedItems(prev => [...prev, { epsn: '', exchanged_product: '', epc: '', eps: '', epp: '', quantity: 1 }]);
    setCurrentExchangeIndex(prev => prev + 1);
  };

  const handleItemChange = (index, field, value, isDispute = true) => {
    const items = isDispute ? [...disputedItems] : [...exchangedItems];
    items[index] = { ...items[index], [field]: value };
    isDispute ? setDisputedItems(items) : setExchangedItems(items);
  };

  const frameSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...form1Data,
      disputed_items: disputedItems,
      exchanged_items: exchangedItems,
    };

    try {
      // Call your API to create the dispute
      console.log("Submitting payload:", payload);
      // await createDispute(payload);
      alert("Dispute submitted successfully!");
    } catch (error) {
      console.error("Error submitting dispute:", error);
    }
  };

  const [dispute, setDispute] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [exchangeProductId, setExchangeProductId] = useState("");
  const [exchangeQuantity, setExchangeQuantity] = useState("");
  const [sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSales, setFilteredSales] = useState([]); // State for filtered sales
  const [filteredDispute, setFilteredDispute] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false); // To manage input disabling
  const inputRef = useRef();
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState("");

  // Add these state variables for totals
  const [returnTotal, setReturnTotal] = useState(0);
  const [exchangeTotal, setExchangeTotal] = useState(0);
  const [balance, setBalance] = useState(0);

  const receiptRef = useRef();
  const [order, setOrder] = useState({
    orderNumber: '',
    date: new Date(),
    salesRep: {
      id: 'system',
      name: 'Exchange Sale',
      email: 'exchange@system.com'
    },
    items: [],
    subtotal: 0,
    tax: 0,
    total: 0,
    discountAmount: 0,
    discountPercentage: 0,
    payment: {
      type: 'Exchange',
      details: {
        transactionId: ''
      }
    }
  });

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "Exchange Receipt",
  });

  useEffect(() => {
    async function fetchOrderDetails() {
      const token = localStorage.getItem("tripstore_login");
      const response = await getSaleByOrderNumber(token);
      if (response.status === 200) {
        setSales(response.data);
        setFilteredSales(response.data);
      }
    }
    fetchOrderDetails();
  }, []);

  // Calculate balance based on epp and price
  const calculateBalance = () => {
    const eppValue = parseFloat(formData.epp) || 0; // Ensure it's a number
    const priceValue = parseFloat(formData.price) || 0; // Ensure it's a number
    return eppValue - priceValue; // Calculate the balance
  };

  const fetchOrderDetails = async ({ orderNumber }) => {
    const token = localStorage.getItem("tripstore_login");
    try {
      const { data, status } = await getSaleByOrderNumber(orderNumber, token);
      if (status === 200) {
        setOrderDetails(data); // Store the fetched order details
      } else {
        alert("Order not found.");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      alert("Failed to fetch order details.");
    }
  };

  // Update getAvailableProducts to maintain original indices
  const getAvailableProducts = () => {
    if (!orderDetails || !orderDetails.items) return [];
    
    // Get array of already selected SKUs
    const selectedSkus = disputedItems.map(item => item.sku_no);
    
    // Return products with their original indices
    return orderDetails.items
      .map((product, index) => ({ ...product, originalIndex: index }))
      .filter(product => !selectedSkus.includes(product.sku));
  };

  // Update handleProductSelection to use the correct product
  const handleProductSelection = (selectedIndex) => {
    const selectedProduct = orderDetails.items[selectedIndex];
    
    if (!selectedProduct) {
      console.error('Product not found');
      return;
    }

    setDisputedItems(prevItems => {
      // Check if product is already selected
      if (prevItems.some(item => item.sku_no === selectedProduct.sku)) {
        alert('This product has already been selected.');
        return prevItems;
      }

      const newItem = {
        sku_no: selectedProduct.sku,
        product_name: selectedProduct.product,
        color: selectedProduct.color,
        size: selectedProduct.size,
        price: selectedProduct.price,
        discount_price: selectedProduct.discount || 0,
        quantity: 1,
        productid: selectedProduct.productId,
        measurementId: selectedProduct.measurementId,
        product_condition: '',
        reason: ''
      };

      const newItems = [...prevItems, newItem];
      
      // Calculate new return total
      const newTotal = newItems.reduce((sum, item) => {
        return sum + (parseFloat(item.price) * (item.quantity || 1));
      }, 0);
      
      setReturnTotal(newTotal);
      setBalance(exchangeTotal - newTotal);
      
      return newItems;
    });
  };

  const handleReceiptChange = async (e) => {
    const { name, value } = e.target;

    // Update the form with the new order_id
    setFormData((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // Only fetch order details if we have at least 5 characters
    if (name === "order_id" && value.length >= 5) {
      try {
        const token = localStorage.getItem("tripstore_login");
        const response = await getSaleByOrderNumber(value, token);

        if (response.status === 200) {
          setOrderDetails(response.data);

          // Format and set the date of sales from the order timestamp
          if (response.data && response.data.timestamp) {
            const dateOfSale = new Date(response.data.timestamp);
            const formattedDate = dateOfSale.toISOString().split("T")[0];

            setFormData((prevForm) => ({
              ...prevForm,
              dos: formattedDate,
              dod: new Date().toISOString().split("T")[0], // Set current date as date of dispute
            }));
          }
        } else {
          alert("Order not found.");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
        alert("Failed to fetch order details.");
      }
    }
  };

  const fetchDispute = async () => {
    try {
      const response = await getAllDispute();
      console.log("Response Data:", response.data);
      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          const allDispute = response.data; // Assuming this is an array of all Dispute
          setFilteredDispute(allDispute);
          setTotalItems(allDispute.length); // Set the total number of items
          const startIndex = (currentPage - 1) * itemsPerPage;
          const paginatedDispute = allDispute.slice(
            startIndex,
            startIndex + itemsPerPage
          );
          setDispute(paginatedDispute);
        } else {
          console.error("Response data is not an array:", response.data);
          setFilteredDispute([]);
        }
      } else {
        console.error("Failed to fetch disputes:", response.status);
        setFilteredDispute([]);
      }
    } catch (error) {
      console.error("Error fetching Dispute:", error);
      setFilteredDispute([]);
    }
  };

  useEffect(() => {
    fetchDispute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Brobot Added

  const [colorArray, setColorArray] = useState([]);

  // Ended Here

  // Fetch all sales from the server
  useEffect(() => {
    async function getSales() {
      const token = localStorage.getItem("tripstore_login");
      const response = await getAllSales(token);
      if (response.status === 200) {
        setSales(response.data);
        setFilteredSales(response.data); // Initialize filtered sales with all sales
      }
    }
    getSales();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleExSelectChange = (e, index) => {
    const selectedColor = e.target.value;
    setExchangedItems(prevItems => {
      const newItems = [...prevItems];
      newItems[index] = {
        ...newItems[index],
        epc: selectedColor
      };
      
      // Recalculate exchange total
      const newTotal = newItems.reduce((sum, item) => sum + (parseFloat(item.epp) * item.quantity || 0), 0);
      setExchangeTotal(newTotal);
      setBalance(newTotal - returnTotal);
      
      return newItems;
    });
  };

  // Add function to calculate totals
  const calculateTotals = (formData) => {
    const returnTotal = formData.products.reduce((sum, product) => {
      return sum + (parseFloat(product.price) || 0);
    }, 0);

    const exchangeTotal = formData.exchanged_products.reduce((sum, product) => {
      return sum + (parseFloat(product.epp) || 0);
    }, 0);

    return {
      returnTotal,
      exchangeTotal,
      balance: exchangeTotal - returnTotal,
    };
  };

  const handleFormChange = (index, type, field, value) => {
    const items = [...formData[type]];
    items[index][field] = value;
    setFormData({ ...formData, [type]: items });
  };

  // Add validation function
  const validateExchangeTotal = () => {
    if (exchangeTotal <= 0) {
      alert('Exchange total must be greater than 0');
      return false;
    }
    return true;
  };

  // Update handleSubmit to include validation
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!disputedItems || disputedItems.length === 0) {
      alert('Please add at least one returned product.');
      return;
    }

    if (!exchangedItems || exchangedItems.length === 0) {
      alert('Please add at least one exchange product.');
      return;
    }

    if (!validateExchangeTotal()) {
      return;
    }

    const token = localStorage.getItem("tripstore_login");
    if (!token) {
      alert('Authentication token not found. Please login again.');
      return;
    }

    try {
      // Create dispute payload with null checks
      const disputePayload = {
        receipt_no: formData.receipt_no,
        order_id: formData.order_id,
        dos: new Date(formData.dos),
        dod: new Date(formData.dod),
        products: disputedItems.map(item => ({
          sku_no: item.sku_no || '',
          product_name: item.product_name || '',
          color: item.color || '',
          size: item.size || '',
          price: parseFloat(item.price) || 0,
          discount_price: parseFloat(item.discount_price || 0),
          product_condition: item.product_condition || '',
          reason: item.reason || '',
          quantity: item.quantity || 1
        })),
        exchanged_products: exchangedItems.map(item => ({
          epsn: item.epsn || '',
          exchanged_product: item.exchanged_product || '',
          epc: item.epc || '',
          eps: item.eps || '',
          epp: parseFloat(item.epp) || 0,
          quantity: item.quantity || 1
        })),
        total_return_amount: returnTotal || 0,
        total_exchange_amount: exchangeTotal || 0,
        balance: balance || 0
      };

      // Create the sale payload
      const salePayload = {
        orderNumber: `EX-${Date.now()}`,
        date: new Date(),
        salesRep: {
          id: 'system',
          name: 'Exchange Sale',
          email: 'exchange@system.com'
        },
        items: exchangedItems.map(item => ({
          product: item.exchanged_product || '',
          quantity: item.quantity || 1,
          price: parseFloat(item.epp) || 0,
          color: item.epc || '',
          size: item.eps || '',
          sku: item.epsn || '',
          status: 'Exchanged'
        })),
        subtotal: exchangeTotal || 0,
        tax: (exchangeTotal || 0) * 0.075,
        total: (exchangeTotal || 0) * 1.075,
        discountAmount: 0,
        discountPercentage: 0,
        payment: {
          type: 'Exchange',
          details: {
            transactionId: `EXCH-${formData.receipt_no}`
          }
        }
      };

      // Update stock with null checks
      if (disputedItems && disputedItems.length > 0) {
        await Promise.all(disputedItems.map(async (item) => {
          if (item.productid && item.sku_no) {
            const stockUpdatePayload = {
              updateData: [{
                measurementId: item.measurementId,
                colorId: item.sku_no,
                amount: item.quantity || 1,
                operation: 'increment'
              }]
            };
            await stockUpdate(token, item.productid, stockUpdatePayload);
          }
        }));
      }

      if (exchangedItems && exchangedItems.length > 0) {
        await Promise.all(exchangedItems.map(async (item) => {
          if (item.productid && item.epsn) {
            const stockUpdatePayload = {
              updateData: [{
                measurementId: item.measurementId,
                colorId: item.epsn,
                amount: item.quantity || 1,
                operation: 'decrement'
              }]
            };
            await stockUpdate(token, item.productid, stockUpdatePayload);
          }
        }));
      }

      // Create records
      const disputeResponse = await createDispute(disputePayload);
      const saleResponse = await createSale(salePayload, 'Exchange', token);

      // Set order state with the complete sale payload
      setOrder(salePayload);

      // Wait for order state to update before printing
      setTimeout(() => {
        if (receiptRef.current && order.orderNumber) {
          handlePrint();
        }
      }, 500);

      // Reset form after successful submission
      resetForm();
      
      alert('Dispute and exchange processed successfully!');

    } catch (error) {
      console.error('Error processing dispute:', error);
      alert(error.message || 'Failed to process dispute. Please try again.');
    }
  };

  // Update resetForm to properly reset the order state
  const resetForm = () => {
    setFormData({
      receipt_no: '',
      order_id: '',
      dos: '',
      dod: new Date().toISOString().split('T')[0],
      products: []
    });
    setDisputedItems([]);
    setExchangedItems([]);
    setReturnTotal(0);
    setExchangeTotal(0);
    setBalance(0);
    setOrderDetails(null);
    setOrder({
      orderNumber: '',
      date: new Date(),
      salesRep: {
        id: 'system',
        name: 'Exchange Sale',
        email: 'exchange@system.com'
      },
      items: [],
      subtotal: 0,
      tax: 0,
      total: 0,
      discountAmount: 0,
      discountPercentage: 0,
      payment: {
        type: 'Exchange',
        details: {
          transactionId: ''
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Define the addProduct function in your component
  const addProduct = (product, color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      epsn: product.productNumber, // Set the SKU number
      exchanged_product: product.name, // Set product name
      epc: color.color || "", // Set color if available
      eps: product.size || "", // Set size if available
      epp: product.price.toString() || "", // Set price as string
    }));
  };

  // Update handleRemoveDisputedItem to ensure proper total recalculation
  const handleRemoveDisputedItem = (index) => {
    setDisputedItems(prevItems => {
      const newItems = prevItems.filter((_, i) => i !== index);
      
      // Recalculate return total
      const newTotal = newItems.reduce((sum, item) => {
        return sum + (parseFloat(item.price) * (item.quantity || 1));
      }, 0);
      
      // Update the return total and balance
      setReturnTotal(newTotal);
      setBalance(exchangeTotal - newTotal);
      
      return newItems;
    });
  };

  const handleRemoveExchangedItem = (index) => {
    setExchangedItems(prevItems => {
      const newItems = prevItems.filter((_, i) => i !== index);
      // Recalculate exchange total
      const newTotal = newItems.reduce((sum, item) => sum + (parseFloat(item.epp) * item.quantity || 0), 0);
      setExchangeTotal(newTotal);
      setBalance(newTotal - returnTotal);
      return newItems;
    });
  };

  // Update handleManualInput to calculate totals
  const handleManualInput = async (value) => {
    setScannedBarcode(value);
    setIsFetching(true);
    const baseUrl = API_ENDPOINT();

    try {
      const product = await fetchProductInfo(value, baseUrl);
      if (!product) {
        alert("Product not found.");
        return;
      }

      setColorArray(product.colors);

      setExchangedItems(prevItems => {
        const newItems = [...prevItems];
        newItems[currentExchangeIndex] = {
          ...newItems[currentExchangeIndex],
          epsn: value,
          exchanged_product: product.name,
          eps: product.size || "",
          epp: product.price.toString() || "",
          quantity: 1,
        };
        
        // Calculate new total
        const newTotal = newItems.reduce((sum, item) => sum + (parseFloat(item.epp) * item.quantity || 0), 0);
        setExchangeTotal(newTotal);
        setBalance(newTotal - returnTotal);
        
        return newItems;
      });
    } catch (error) {
      console.error(error);
      alert("Error fetching product information.");
    } finally {
      setIsFetching(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Make sure to initialize exchangedItems if it's empty
  useEffect(() => {
    if (exchangedItems.length === 0) {
      setExchangedItems([{ epsn: '', exchanged_product: '', epc: '', eps: '', epp: '', quantity: 1 }]);
    }
  }, [exchangedItems.length]);

  // Add useEffect to ensure totals are always in sync
  useEffect(() => {
    const newReturnTotal = disputedItems.reduce((sum, item) => {
      return sum + (parseFloat(item.price) * (item.quantity || 1));
    }, 0);
    
    setReturnTotal(newReturnTotal);
    setBalance(exchangeTotal - newReturnTotal);
  }, [disputedItems, exchangeTotal]);

  async function getDispute() {
    const response = await getAllDispute();
    setDispute(response.data);
    setFilteredDispute(response.data); // Initialize filtereddispute with all dispute
  }

  useEffect(() => {
    getDispute();
  }, []);

  async function deleteDisputefn(id) {
    const response = await deleteDispute(id);
    if (response.status === 200) {
      getDispute();
    }
  }

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  // Updated receipt lookup function with better error handling and debugging
  const handleReceiptLookup = async (receiptNo) => {
    if (!receiptNo) {
      alert('Please enter a receipt number');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("tripstore_login");
      if (!token) {
        alert('Authentication token not found. Please login again.');
        return;
      }

      const response = await getSaleByOrderNumber(receiptNo, token);
      
      if (response.status !== 200 || !response.data) {
        throw new Error('Receipt not found');
      }

      const data = response.data;
      console.log('Received data:', data); // Debug log

      // Set order details
      setOrderDetails(data);
      
      // Set form data
      setFormData(prev => ({
        ...prev,
        receipt_no: data.orderNumber || receiptNo,
        order_id: data._id,
        dos: data.timestamp ? new Date(data.timestamp).toISOString().split('T')[0] : '',
        dod: new Date().toISOString().split('T')[0] // Set current date as dispute date
      }));

      // Reset selections
      setDisputedItems([]);
      setExchangedItems([]);
      setReturnTotal(0);
      setExchangeTotal(0);
      setBalance(0);

    } catch (error) {
      console.error('Error details:', error);
      alert('Receipt not found. Please check the receipt number and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Update the receipt input to handle Enter key
  const handleReceiptKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReceiptLookup(formData.receipt_no);
    }
  };

  return (
    <div className="mt-2 p-2">
      <Popup
        trigger={
          <button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">
            Create Dispute
          </button>
        }
        position="right center"
        modal
      >
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl">
            <div
              className="form-container"
              style={{ maxHeight: "700px", overflowY: "auto" }}
            >
              <form
                onSubmit={handleSubmit}
                className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white"
              >
                <p className="text-center text-lg font-medium">
                  Create Dispute
                </p>
                <div className="grid grid-cols-2 gap-2">
                  {/* Receipt No with Lookup */}
                  <div className="mb-4">
                    <label htmlFor="receipt_no" className="block text-sm font-medium text-gray-700">
                      Receipt No
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        id="receipt_no"
                        className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                        value={formData.receipt_no}
                        onChange={(e) => {
                          setFormData({ ...formData, receipt_no: e.target.value.trim() });
                          if (orderDetails && e.target.value !== formData.receipt_no) {
                            setOrderDetails(null);
                          }
                        }}
                        onKeyPress={handleReceiptKeyPress}
                        placeholder="Enter receipt number and press Enter"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => handleReceiptLookup(formData.receipt_no)}
                        disabled={isLoading || !formData.receipt_no}
                        className={`px-4 py-2 bg-black text-white rounded-lg transition-colors ${
                          isLoading || !formData.receipt_no
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:bg-gray-800'
                        }`}
                      >
                        {isLoading ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Loading...
                          </span>
                        ) : (
                          'Check'
                        )}
                      </button>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">
                      Enter the receipt number from the original sale
                    </p>
                  </div>

                  {/* Order Summary with loading state */}
                  {isLoading ? (
                    <div className="mb-4 p-4 bg-gray-50 rounded-lg animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
                      <div className="space-y-3">
                        <div className="h-3 bg-gray-200 rounded"></div>
                        <div className="h-3 bg-gray-200 rounded"></div>
                        <div className="h-3 bg-gray-200 rounded"></div>
                      </div>
                    </div>
                  ) : orderDetails ? (
                    <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                      <h3 className="text-lg font-medium mb-2">Order Summary</h3>
                      <div className="grid grid-cols-2 gap-4 text-sm">
                        <div>
                          <span className="font-medium">Order Date:</span>{' '}
                          {new Date(orderDetails.date).toLocaleDateString()}
                        </div>
                        <div>
                          <span className="font-medium">Total Amount:</span>{' '}
                          ₦{orderDetails.total?.toFixed(2)}
                        </div>
                        <div>
                          <span className="font-medium">Payment Type:</span>{' '}
                          {orderDetails.payment?.type}
                        </div>
                        <div>
                          <span className="font-medium">Items Count:</span>{' '}
                          {orderDetails.items?.length}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Product Selection - Only show if order details are loaded */}
                  {orderDetails && (
                    <div className="mb-4">
                      <label htmlFor="product_selection" className="block text-sm font-medium text-gray-700 mb-1">
                        Select Returned Product
                      </label>
                      <div className="relative">
                        <select
                          id="product_selection"
                          className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                          onChange={(e) => {
                            if (e.target.value) {
                              handleProductSelection(parseInt(e.target.value, 10));
                            }
                            // Reset the select element
                            e.target.value = '';
                          }}
                          value=""
                        >
                          <option value="">-- Select Product --</option>
                          {getAvailableProducts().map((product) => (
                            <option 
                              key={product.sku} 
                              value={product.originalIndex}
                            >
                              {product.product} - {product.color} - {product.size} - ₦{product.price}
                            </option>
                          ))}
                        </select>
                        {getAvailableProducts().length === 0 && (
                          <div className="mt-1 text-sm text-gray-500">
                            All products from this order have been selected
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Auto-fill other fields based on selected product */}

                  {/* Neww Start Here */}
                  {formData.products.map((product, index) => (
                    <>
                      <div>
                        <label htmlFor="sku_no" className=" ">
                          SKU No
                        </label>
                        <div className="relative">
                          <input
                            name="sku_no"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter SKU No"
                            value={product.sku_no}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "sku_no",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="product_name" className=" ">
                          Product Name
                        </label>
                        <div className="relative">
                          <input
                            name="product_name"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Product Name"
                            value={product.product_name}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "product_name",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="color" className=" ">
                          Color
                        </label>
                        <div className="relative">
                          <input
                            name="color"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Color"
                            value={product.color}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "color",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="size" className=" ">
                          Size
                        </label>
                        <div className="relative">
                          <input
                            name="size"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Size"
                            value={product.size}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "size",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="price" className=" ">
                          Price
                        </label>
                        <div className="relative">
                          <input
                            name="price"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Price"
                            value={product.price}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "price",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="discount_price" className=" ">
                          Discount (%)
                        </label>
                        <div className="relative">
                          <input
                            name="discount_price"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Product Discount Percentage"
                            value={product.discount_price}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "discount_price",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Product Condition */}
                      <div>
                        <label htmlFor="product_condition" className=" ">
                          Product Condition
                        </label>
                        <div className="relative">
                          <input
                            name="product_condition"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Product Condition"
                            value={product.product_condition}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "product_condition",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      {/* Reason */}
                      <div>
                        <label htmlFor="reason" className=" ">
                          Reason
                        </label>
                        <div className="relative">
                          <input
                            name="reason"
                            type="text"
                            className="w-full rounded-lg border-gray-200 p-2   text-sm shadow-sm"
                            placeholder="Enter Reason"
                            value={product.reason}
                            onChange={(e) =>
                              handleFormChange(
                                index,
                                "products",
                                "reason",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </>
                  ))}

                  {/* Disputed Items Section with Total and Details */}
                  <div className="col-span-2 mb-4">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-medium">Returned Products</h3>
                      <div className="text-right font-medium text-gray-800">
                        Total Return Amount: ₦{returnTotal.toFixed(2)}
                      </div>
                    </div>
                    {disputedItems.map((item, index) => (
                      <div key={index} className="relative border border-gray-200 p-4 rounded-lg mb-4 hover:border-gray-300 transition-colors">
                        <div className="flex justify-between items-start mb-3">
                          <div className="font-medium text-gray-800">
                            Product #{index + 1}
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="text-gray-600">
                              Price: ₦{(parseFloat(item.price) * (item.quantity || 1)).toFixed(2)}
                            </div>
                            <button
                              type="button"
                              onClick={() => handleRemoveDisputedItem(index)}
                              className="group relative inline-flex items-center justify-center p-2 hover:bg-red-50 rounded-full transition-colors"
                              title="Remove Item"
                            >
                              <svg 
                                className="w-5 h-5 text-red-400 group-hover:text-red-600 transition-colors" 
                                fill="none" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                              >
                                <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                              <span className="sr-only">Remove item</span>
                            </button>
                          </div>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-4">
                          {/* SKU Number */}
                          <div>
                            <label className="text-sm text-gray-600">SKU No</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.sku_no || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Product Name */}
                          <div>
                            <label className="text-sm text-gray-600">Product Name</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.product_name || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Color */}
                          <div>
                            <label className="text-sm text-gray-600">Color</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.color || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Size */}
                          <div>
                            <label className="text-sm text-gray-600">Size</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.size || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Price */}
                          <div>
                            <label className="text-sm text-gray-600">Price</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={`₦${parseFloat(item.price).toFixed(2)}` || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Discount */}
                          <div>
                            <label className="text-sm text-gray-600">Discount (%)</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.discount_price || '0'}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Product Condition */}
                          <div>
                            <label className="text-sm text-gray-600">Product Condition</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                                value={item.product_condition || ''}
                                onChange={(e) => {
                                  const newItems = [...disputedItems];
                                  newItems[index].product_condition = e.target.value;
                                  setDisputedItems(newItems);
                                }}
                              />
                            </div>
                          </div>

                          {/* Reason */}
                          <div>
                            <label className="text-sm text-gray-600">Reason</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                                value={item.reason || ''}
                                onChange={(e) => {
                                  const newItems = [...disputedItems];
                                  newItems[index].reason = e.target.value;
                                  setDisputedItems(newItems);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    
                    {/* Summary of Return Total */}
                    {disputedItems.length > 0 && (
                      <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                        <div className="space-y-2">
                          {disputedItems.map((item, index) => (
                            <div key={index} className="flex justify-between text-sm">
                              <span className="text-gray-600">
                                {item.product_name} ({item.quantity || 1}x)
                              </span>
                              <span className="text-gray-800">
                                ₦{(parseFloat(item.price) * (item.quantity || 1)).toFixed(2)}
                              </span>
                            </div>
                          ))}
                          <div className="pt-2 mt-2 border-t border-gray-200">
                            <div className="flex justify-between font-medium">
                              <span>Total Return Amount</span>
                              <span>₦{returnTotal.toFixed(2)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Exchange Products Section */}
                  <div className="col-span-2 mb-4">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-medium">Exchange Products</h3>
                      <div className="flex items-center gap-4">
                        <div className="text-right font-medium text-gray-800">
                          Total Exchange Amount: ₦{exchangeTotal.toFixed(2)}
                        </div>
                        <button
                          type="button"
                          onClick={handleAddExchangedItem}
                          className="inline-flex items-center px-4 py-2 border border-black bg-black text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition-colors rounded-lg"
                        >
                          <svg 
                            className="w-5 h-5 mr-2" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth="2" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path d="M12 4v16m8-8H4" />
                          </svg>
                          Add Exchange Product
                        </button>
                      </div>
                    </div>
                    
                    {exchangedItems.map((item, index) => (
                      <div key={index} className="relative border border-gray-200 p-6 rounded-lg mb-4 hover:border-gray-300 transition-colors">
                        <div className="flex justify-between items-start mb-4">
                          <div className="font-medium text-gray-800">
                            Exchange Product #{index + 1}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveExchangedItem(index)}
                            className="group relative inline-flex items-center justify-center p-2 hover:bg-red-50 rounded-full transition-colors"
                            title="Remove Exchange Product"
                          >
                            <svg 
                              className="w-5 h-5 text-red-400 group-hover:text-red-600 transition-colors" 
                              fill="none" 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth="2" 
                              viewBox="0 0 24 24" 
                              stroke="currentColor"
                            >
                              <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            <span className="sr-only">Remove exchange product</span>
                          </button>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-4">
                          {/* Exchanged Product SKU No Input */}
                          <div>
                            <label htmlFor={`epsn_input_${index}`}>Exchanged Product SKU No</label>
                            <div className="relative">
                              <input
                                name={`epsn_input_${index}`}
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                                placeholder="Enter Exchanged Product SKU No"
                                value={index === currentExchangeIndex ? scannedBarcode : ''}
                                onChange={(e) => {
                                  setCurrentExchangeIndex(index);
                                  handleManualInput(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          {/* Stored SKU No */}
                          <div>
                            <label htmlFor={`epsn_${index}`}>Stored SKU No</label>
                            <div className="relative">
                              <input
                                name={`epsn_${index}`}
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.epsn || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Product Name */}
                          <div>
                            <label htmlFor={`product_name_${index}`}>Product Name</label>
                            <div className="relative">
                              <input
                                name={`product_name_${index}`}
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.exchanged_product || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Color Selection */}
                          <div>
                            <label htmlFor={`epc_${index}`}>Product Color</label>
                            <div className="relative">
                              <select
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                                name={`epc_${index}`}
                                value={item.epc || ''}
                                onChange={(e) => handleExSelectChange(e, index)}
                              >
                                <option value="">-- Select Color --</option>
                                {colorArray && colorArray.map((color, colorIndex) => (
                                  <option key={colorIndex} value={color.color}>
                                    {color.color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* Display Selected Color */}
                          <div>
                            <label className="text-sm text-gray-600">Selected Color</label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.epc || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Size */}
                          <div>
                            <label htmlFor={`eps_${index}`}>Size</label>
                            <div className="relative">
                              <input
                                name={`eps_${index}`}
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.eps || ''}
                                readOnly
                              />
                            </div>
                          </div>

                          {/* Price */}
                          <div>
                            <label htmlFor={`epp_${index}`}>Price</label>
                            <div className="relative">
                              <input
                                name={`epp_${index}`}
                                type="text"
                                className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm bg-gray-50"
                                value={item.epp || ''}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Balance Section with Professional Styling */}
                  <div className="col-span-2 border-t border-gray-200 pt-4 mt-4">
                    <div className="text-right">
                      <div className="font-medium text-lg text-gray-800">
                        Balance: ₦{balance.toFixed(2)}
                      </div>
                      {exchangeTotal < returnTotal && (
                        <div className="flex items-center justify-end mt-2 text-sm text-red-600">
                          <svg 
                            className="w-5 h-5 mr-2" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth="2" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                          <span>
                            Exchange total (₦{exchangeTotal.toFixed(2)}) must be equal to or greater than 
                            return total (₦{returnTotal.toFixed(2)}). 
                            Please add more products to exchange.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Date Fields */}
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Date of Sale
                      </label>
                      <input
                        type="date"
                        className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                        value={formData.dos}
                        onChange={(e) => setFormData({ ...formData, dos: e.target.value })}
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Date of Dispute
                      </label>
                      <input
                        type="date"
                        className="w-full rounded-lg border-gray-200 p-2 text-sm shadow-sm"
                        value={formData.dod}
                        onChange={(e) => setFormData({ ...formData, dod: e.target.value })}
                        required
                      />
                    </div>
                  </div>

                  {/* Update the hidden PrintableReceipt section */}
                  <div style={{ display: 'none' }}>
                    {order && order.orderNumber && (
                      <PrintableReceipt
                        ref={receiptRef}
                        order={order}
                        type="exchange"
                      />
                    )}
                  </div>

                  {/* Submit Button with Disabled State */}
                  <button
                    type="submit"
                    className={`w-full rounded-lg px-3 py-1 text-sm font-medium text-white ${
                      exchangeTotal < returnTotal 
                        ? 'bg-gray-400 cursor-not-allowed' 
                        : 'bg-black hover:bg-gray-800'
                    }`}
                    disabled={exchangeTotal < returnTotal}
                  >
                    Create Dispute
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <div className="relative mb-3">
        <label htmlFor="Search" className=" ">
          {" "}
          Search{" "}
        </label>
        <input
          type="text"
          id="Search"
          placeholder="Search for..."
          className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
          value={searchTerm}
          onChange={handleSearch}
        />
        <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
          <button type="button" className="text-gray-600 hover:text-gray-700">
            <span className="sr-only">Search</span>
            <FiSearch />
          </button>
        </span>
      </div>
      <div className="rounded-lg border border-gray-200">
        <div className="overflow-x-auto rounded-t-lg">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  S/N
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Receipt No
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  SKU No
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Product Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Color
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Size
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Price
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Discount Price
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  PC
                </th>{" "}
                {/* Product Condition */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Reason
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  DOS
                </th>{" "}
                {/* Date of Sales */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  DOD
                </th>{" "}
                {/* Date of Dispute */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Exchanged Product
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPSN
                </th>{" "}
                {/* Exchanged Product SKU No */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPC
                </th>{" "}
                {/* Exchanged Product Color */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPS
                </th>{" "}
                {/* Exchanged Product Size */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  EPP
                </th>{" "}
                {/* Exchanged Product Price */}
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Balance
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Array.isArray(filteredDispute) && filteredDispute.length > 0 ? (
                filteredDispute.map((dispute, index) => (
                  <tr key={dispute._id}>
                    <td className="text-center py-2 font-medium text-gray-900">
                      {index + 1}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.receipt_no}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.sku_no}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.product_name}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.color}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.size}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      ₦{dispute.price}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.discount_price}%
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.product_condition}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.reason}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {new Date(dispute.dos).toLocaleDateString()}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {new Date(dispute.dod).toLocaleDateString()}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.exchanged_product}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.epsn}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.epc}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      {dispute.eps}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      ₦{dispute.epp}
                    </td>
                    <td className="text-center py-2 text-gray-700">
                      ₦{dispute.balance}
                    </td>{" "}
                    {/* Calculate balance here */}
                    <td className="text-center py-2">
                      <Popup
                        trigger={(open) => (
                          <button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">
                            ACTION
                          </button>
                        )}
                        position="right bottom"
                        closeOnDocumentClick
                      >
                        <button
                          className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black"
                          onClick={() => deleteDisputefn(dispute._id)}
                        >
                          {" "}
                          DELETE{" "}
                        </button>
                      </Popup>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="18" className="text-center py-2 text-gray-700">
                    No disputes found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Dispute;
